import { UiButton, UiForm, UiInput, message, UiModal } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { createBlogAlbumCategoryEffect, updateBlogAlbumCategoryEffect } from '@vkph/common/store/blogs';
import { GlobalModalNames, GlobalModalsStorePayloads } from '@vkph/common/store/global-modals';
import { BlogAlbumCategoryModel } from '@vkph/common/types/models';
import {
  MAX_LENGTH_INPUT_50,
  requiredRule,
  getErrorResponseMessage,
  normalizeValueTrimStart,
} from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { MarkerColorField, MarkerColorMap } from '../../../marker-color-field';

type Props = NonNullable<
  GlobalModalsStorePayloads[GlobalModalNames.BlogAlbumCategoryCreateUpdate]['payload']
>;

type FormValues = BlogAlbumCategoryModel;

export const BlogAlbumCategoryCreateUpdateModal: FC<Props> = (props) => {
  const { category, onClose, blogId, onSuccess } = props;
  const [form] = UiForm.useForm<FormValues>();
  const isEdit = Boolean(category);

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        id: category.id,
        name: category.name,
        markerColor: category.markerColor,
      });
    }
  }, [category]);

  const onFinish = (formValues: FormValues) => {
    const { id, ...restValues } = formValues;

    const finishEffect = isEdit ? updateBlogAlbumCategoryEffect : createBlogAlbumCategoryEffect;

    finishEffect({ ...restValues, blogId, categoryId: id })
      .then((data) => {
        message.success(`Категория успешно ${isEdit ? 'обновлена' : 'создана'}`);
        onSuccess?.(data);
        onClose();
      })
      .catch((e) =>
        message.error(getErrorResponseMessage(e, `Ошибка ${isEdit ? 'обновления' : 'создания'} категории`)),
      );
  };

  return (
    <UiForm
      size="large"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      initialValues={{ markerColor: MarkerColorMap[0] }}
    >
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(`${isEdit ? 'Редактировать' : 'Добавить'} категорию`)}
        />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm.Item name="id" noStyle hidden />
        <UiForm.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <UiForm.Item
              name="name"
              label="Название"
              normalize={normalizeValueTrimStart}
              rules={[requiredRule]}
              extra={`Использовано ${getFieldValue('name')?.length || 0}/50`}
            >
              <UiInput placeholder="Введите название" maxLength={MAX_LENGTH_INPUT_50} />
            </UiForm.Item>
          )}
        </UiForm.Item>
        <UiForm.Item name="markerColor" label="Цвет маркера">
          <MarkerColorField />
        </UiForm.Item>
      </UiModal.Content>
      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <UiButton
                type="primary"
                label={isEdit ? 'Сохранить' : 'Добавить'}
                onClick={form.submit}
                disabled={!getFieldValue('name')?.length}
              />
            )}
          </UiForm.Item>
          <UiButton type="tertiary" label="Отменить" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
