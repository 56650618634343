import { SkillType } from '@vkph/common/types/models';

enum SkillText {
  OneUp = 'Навык',
  OneDown = 'навык',
  ManyUp = 'Навыки',
  ManyDown = 'навыки',
  ThanDown = 'навыком',
}

enum CompetenceText {
  OneUp = 'Компетенция',
  OneDown = 'компетенцию',
  ManyUp = 'Компетенции',
  ManyDown = 'компетенции',
  ThanDown = 'компетенцией',
}

export const skillsTextMap = {
  [SkillType.Skill]: SkillText,
  [SkillType.Competence]: CompetenceText,
};
