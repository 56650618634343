import { Notification } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinkContentModel, NotificationModel } from '@vkph/common/types/models';
import {
  RouteNames,
  getFullNameWithoutPatronymic,
  getRoutePath,
  parseEntityText,
  parseMentionsToPlainText,
} from '@vkph/common/utils';

import { getNotificationDescription } from '../../../utils';

interface Props {
  notification: NotificationModel<LinkContentModel>;
}

export const NotificationBlogDefault: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    content,
  } = notification;
  const { link, title } = content;

  const navigate = useNavigate();

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: image } satisfies UiAvatarProps;
  const parsedText = title && parseMentionsToPlainText(parseEntityText(title).textValue);

  const onOpenLink = () => navigate(link);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <UiTypography.Link strong style={{ display: 'block' }} onClick={onOpenLink}>
            <UiTruncateMarkup truncate lines={2}>
              {parsedText}
            </UiTruncateMarkup>
          </UiTypography.Link>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
