import { UiButton } from '@vkph/ui';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';

import { markNotificationReadEffect } from '@vkph/common/store';
import {
  ProjectsTasksModel,
  ContentSkillModel,
  ContentSurveyModel,
  NotificationAlbumImageCommentModel,
  NotificationModel,
  NotificationTimelineRecordModel,
  EventModel,
  FileStorageListEntryModel,
  CalendarEventModel,
  ListModel,
  SitePageModel,
} from '@vkph/common/types/models';

import {
  isNotificationExternal,
  isNotificationOld,
  isNotificationSomeOfTypes,
} from '../../../types/guards/notification-guards';
import {
  notificationSkills,
  notificationBlogs,
  notificationGamification,
  notificationSurvey,
  notificationEvents,
  notificationCalendarGroupEvent,
  notificationFile,
  notificationMicropost,
  notificationNews,
  notificationFileStorage,
  notificationRecord,
  notificationIdea,
  notificationTasks,
  notificationProjects,
  notificationAlbumImage,
  notificationList,
  notificationSitector,
} from '../constants';
import { NotificationAlbumImageComment } from '../notification/album-image/comment/NotificationAlbumImageComment';
import { NotificationBlog } from '../notification/blog/NotificationBlog';
import { NotificationCalendarEvent } from '../notification/calendar-event/NotificationCalendarEvent';
import { NotificationEvent } from '../notification/event/NotificationEvent';
import { NotificationExternal } from '../notification/external/NotificationExternal';
import { NotificationFileStorage } from '../notification/file-storage/NotificationFileStorage';
import { NotificationFile } from '../notification/file/NotificationFile';
import { NotificationGamification } from '../notification/gamification/NotificationGamification';
import { NotificationIdea } from '../notification/idea/NotificationIdea';
import { NotificationList } from '../notification/list/NotificationList';
import { NotificationMicropost } from '../notification/micropost/NotificationMicropost';
import { NotificationNews } from '../notification/news/NotificationNews';
import { NotificationProjects } from '../notification/projects/NotificationProjects';
import { NotificationSitector } from '../notification/sitector/NotificationSitector';
import { NotificationSkill } from '../notification/skill/NotificationSkill';
import { NotificationSurvey } from '../notification/survey/NotificationSurvey';
import { NotificationTasks } from '../notification/tasks/NotificationTasks';
import { NotificationTimelineRecord } from '../notification/timeline-record/NotificationTimelineRecord';

interface NotificationCardProps {
  notification: NotificationModel;
}

export const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { notification } = props;
  const { content } = notification;
  const isOtherNotification = useMemo(
    () =>
      isNotificationSomeOfTypes(notification, [
        ...notificationSkills,
        ...notificationBlogs,
        ...notificationGamification,
        ...notificationSurvey,
        ...notificationEvents,
        ...notificationCalendarGroupEvent,
        ...notificationFile,
        ...notificationMicropost,
        ...notificationNews,
        ...notificationFileStorage,
        ...notificationRecord,
        ...notificationIdea,
        ...notificationTasks,
        ...notificationProjects,
        ...notificationAlbumImage,
        ...notificationList,
        ...notificationSitector,
      ]),
    [],
  );

  const isOldNotificationWithContent = Boolean(
    isNotificationOld(notification) && notification.content.description,
  );

  const isValidNotification = isNotificationOld(notification)
    ? isNotificationSomeOfTypes(notification, notificationSkills) || isOldNotificationWithContent
    : content && !isEmpty(content);

  // TODO: B2BCORE-2551 Защита от старых нотификаций с не валидными данными
  if (!isValidNotification) {
    return null;
  }

  return (
    <UiButton.Decorator onClick={() => markNotificationReadEffect(notification.id)}>
      {isNotificationSomeOfTypes<ContentSkillModel>(notification, notificationSkills) && (
        <NotificationSkill notification={notification} />
      )}
      {isNotificationSomeOfTypes<ContentSurveyModel>(notification, notificationSurvey) && (
        <NotificationSurvey notification={notification} />
      )}
      {isNotificationSomeOfTypes<EventModel>(notification, notificationEvents) && (
        <NotificationEvent notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationGamification) && (
        <NotificationGamification notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationBlogs) && (
        <NotificationBlog notification={notification} />
      )}
      {isNotificationSomeOfTypes<CalendarEventModel>(notification, notificationCalendarGroupEvent) && (
        <NotificationCalendarEvent notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationFile) && (
        <NotificationFile notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationMicropost) && (
        <NotificationMicropost notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationNews) && (
        <NotificationNews notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationIdea) && (
        <NotificationIdea notification={notification} />
      )}
      {isNotificationSomeOfTypes<NotificationTimelineRecordModel>(notification, notificationRecord) && (
        <NotificationTimelineRecord notification={notification} />
      )}
      {isNotificationSomeOfTypes<NotificationAlbumImageCommentModel>(
        notification,
        notificationAlbumImage,
      ) && <NotificationAlbumImageComment notification={notification} />}
      {isNotificationSomeOfTypes<FileStorageListEntryModel>(notification, notificationFileStorage) && (
        <NotificationFileStorage notification={notification} />
      )}
      {isNotificationSomeOfTypes<ProjectsTasksModel>(notification, notificationTasks) && (
        <NotificationTasks notification={notification} />
      )}
      {isNotificationSomeOfTypes<ProjectsTasksModel>(notification, notificationProjects) && (
        <NotificationProjects notification={notification} />
      )}
      {isNotificationSomeOfTypes<ListModel>(notification, notificationList) && (
        <NotificationList notification={notification} />
      )}
      {isNotificationSomeOfTypes<SitePageModel>(notification, notificationSitector) && (
        <NotificationSitector notification={notification} />
      )}
      {isNotificationExternal(notification) && <NotificationExternal notification={notification} />}

      {!isOtherNotification && !isNotificationExternal(notification) && (
        // TODO: B2BCORE-2551 Пока на бэке не будет нужных api будет простой шаблон как у блогов
        <NotificationBlog notification={notification} />
      )}
    </UiButton.Decorator>
  );
};
