import { UiTypography } from '@vkph/ui';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import ru from 'date-fns/locale/ru';
import round from 'lodash/round';
import React, { FC } from 'react';

import { capitalizeFirstLetter } from '@vkph/common/utils';

import { Weather } from '../../../types';
import { WeatherIcon } from '../type/WeatherType';
import styles from './WeatherDay.scss';

const titleStyles: React.CSSProperties = { fontWeight: 600, marginBottom: 0 };

type Props = {
  day: Weather.Day;
};

const getWeekDate = (date: string) => {
  const dayDate = new Date(date);

  if (dayDate && isValid(dayDate)) {
    const dayOfWeek = format(dayDate, 'EEEEEE dd.MM', { locale: ru });

    return capitalizeFirstLetter(dayOfWeek);
  }

  return null;
};

export const WeatherDay: FC<Props> = (props) => {
  const { day } = props;

  return (
    <div className={styles.weatherDay}>
      {getWeekDate(day.date)}

      <span className={styles.weatherDay__icon}>
        <WeatherIcon size={52} type={day.meta.icon} />
      </span>

      <UiTypography.Title level={4} style={titleStyles}>
        {round(day.tempSummary.max)}
        &deg;&nbsp;
        {round(day.tempSummary.min)}
        &deg;
      </UiTypography.Title>
    </div>
  );
};
