import { Notification, RouterLink } from '@vkph/components';
import {
  UiAvatarProps,
  UiButton,
  UiDivider,
  UiFile,
  UiSpace,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { GroupFileCreatedContentModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFormattedDate, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';
import { getFormattedFileSize } from '@vkph/ui/utils';

interface Props {
  notification: NotificationModel<GroupFileCreatedContentModel>;
}

export const NotificationFileBlog: FC<Props> = (props) => {
  const { notification } = props;
  const navigate = useNavigate();

  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    content: { blog, file },
  } = notification;

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const onFileClick = () => {
    navigate(getRoutePath(RouteNames.GroupFile, { id: blog.slug, fileId: file.id }));
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle="Загружен новый файл"
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <UiSpace full size={4} direction="horizontal">
            <UiTypography.Text type="secondary">в группу:</UiTypography.Text>
            <RouterLink strong to={getRoutePath(RouteNames.GroupView, { id: blog.slug })}>
              {blog.name}
            </RouterLink>
          </UiSpace>
          <UiFile
            title={
              <UiTypography.Text strong type="secondary">
                <UiTruncateMarkup truncate tooltipProps={{ title: file.name }}>
                  {file.name}
                </UiTruncateMarkup>
              </UiTypography.Text>
            }
            subtitle={
              <UiTypography.Text type="secondary">
                {getFormattedFileSize(file.size)}
                <UiDivider.Dot type="secondary" />
                {createdAt ? getFormattedDate(createdAt, 'date') : ''}
              </UiTypography.Text>
            }
            fileName={file.name}
          />
          <UiButton label="Посмотреть" type="primary" onClick={onFileClick} disabled={file.isDeleted} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
