import { Layout, UiDrawer } from '@vkph/ui';
import React, { FC } from 'react';

import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { useToggle } from '@vkph/ui/hooks';

import { FileActions } from '../../actions';
import { FileHistory } from '../../history';
import { FileInfoCard } from '../../info-card';
import { FileInfoCardGeneral } from '../../info-card/general';
import { FileVersions } from '../../versions';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileId: FileStorageEntryId;
  fileStorageRootId?: FileStorageEntryId;
};

export const FileViewMobile: FC<Props> = (props) => {
  const { fileInfoStorage, fileId, fileStorageRootId } = props;
  const [isFileHistoryOpen, toggleFileHistoryOpen] = useToggle([false, true]);
  const [isFileInfoGeneralOpen, toggleFileInfoGeneralOpen] = useToggle([false, true]);

  return (
    <Layout.Content>
      <FileInfoCard
        fileInfoStorage={fileInfoStorage}
        fileStorageRootId={fileStorageRootId}
        onFileHistoryOpen={toggleFileHistoryOpen}
        onFileInfoGeneralOpen={toggleFileInfoGeneralOpen}
      />
      <FileActions fileInfoStorage={fileInfoStorage} />
      <FileVersions id={fileId} fileInfoStorage={fileInfoStorage} />
      <UiDrawer
        placement="bottom"
        title="История изменений"
        open={isFileHistoryOpen}
        onClose={() => toggleFileHistoryOpen()}
      >
        <FileHistory id={fileId} />
      </UiDrawer>
      <UiDrawer
        placement="bottom"
        title="Общая информация"
        open={isFileInfoGeneralOpen}
        onClose={() => toggleFileInfoGeneralOpen()}
      >
        <FileInfoCardGeneral fileInfoStorage={fileInfoStorage} id={fileId} />
      </UiDrawer>
    </Layout.Content>
  );
};
