import React, { FC } from 'react';

import {
  BlogFullModel,
  LinkContentModel,
  NotificationBlogEntryCommentModel,
  NotificationBlogEntryReactionModel,
  NotificationBlogInviteContentModel,
  NotificationModel,
  NotificationTypes,
  PostBlogModel,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { notificationBlogPost, notificationBlogsComment, notificationBlogsInvite } from '../../constants';
import { NotificationBlogAdministrator } from './administrator/NotificationBlogAdministrator';
import { NotificationBlogDefault } from './default/NotificationBlogDefault';
import { NotificationBlogEntryComment } from './entry/comment/NotificationBlogEntryComment';
import { NotificationBlogEntryReaction } from './entry/reaction/NotificationBlogEntryReaction';
import { NotificationBlogInvite } from './invite/NotificationBlogInvite';
import { NotificationBlogPost } from './post/NotificationBlogPost';
import { NotificationBlogRequest } from './request/NotificationBlogRequest';

interface Props {
  notification: NotificationModel;
}

export const NotificationBlog: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<BlogFullModel>(notification, [
      NotificationTypes.BlogGroupAdministratorIsBlocked,
    ])
  ) {
    return <NotificationBlogAdministrator notification={notification} />;
  }

  if (isNotificationSomeOfTypes<NotificationBlogEntryCommentModel>(notification, notificationBlogsComment)) {
    return <NotificationBlogEntryComment notification={notification} />;
  }

  if (isNotificationSomeOfTypes<PostBlogModel>(notification, notificationBlogPost)) {
    return <NotificationBlogPost notification={notification} />;
  }

  if (isNotificationSomeOfTypes<NotificationBlogInviteContentModel>(notification, notificationBlogsInvite)) {
    return <NotificationBlogInvite notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationBlogEntryReactionModel>(notification, [
      NotificationTypes.BlogEntryReactionCreated,
    ])
  ) {
    return <NotificationBlogEntryReaction notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<BlogFullModel>(notification, [
      NotificationTypes.BlogUserInviteRequestClosedBlog,
      NotificationTypes.BlogUserApproveRequestClosedBlog,
      NotificationTypes.BlogUserRejectRequestClosedBlog,
    ])
  ) {
    return <NotificationBlogRequest notification={notification} />;
  }

  // TODO: B2BCORE-2551 Пока на бэке не будет нужных api будет простой шаблон как у блогов
  return <NotificationBlogDefault notification={notification as NotificationModel<LinkContentModel>} />;
};
