import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const NAVBAR_ACTIONS_ID = 'navbar-actions-id';

export const WritePostMobileActions: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const containerElement = document.getElementById(NAVBAR_ACTIONS_ID);

    setContainer(containerElement);
  }, []);

  if (container) {
    return createPortal(children, container);
  }

  return null;
};
