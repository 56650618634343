import { UserLinkCell } from '@vkph/components';
import {
  UiButton,
  UiCard,
  UiDescriptions,
  UiEmpty,
  UiCol,
  UiSkeleton,
  UiSpace,
  UiTypography,
  UiTruncateMarkup,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, useState } from 'react';

import { useAbstractStorage, useUnmount } from '@vkph/common/hooks';
import { getFileStorageEntryHistoryStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { createArrayMock, getFormattedDate } from '@vkph/common/utils';
import { usePagination } from '@vkph/components/hooks';
import { useSpace } from '@vkph/ui/providers/space';

type Props = {
  id: FileStorageEntryId;
};

export const FILESTORAGE_HISTORY_PAGE_SIZE = 5;

const historyLoadingMock = createArrayMock(FILESTORAGE_HISTORY_PAGE_SIZE, (_, idx) => (
  <UiCol key={idx} span={24}>
    <UiSkeleton loading height={44} block width={373 - 40} />
  </UiCol>
));

export const fileStorageEntryHistoryStorage = getFileStorageEntryHistoryStorage();

export const FileHistory: FC<Props> = (props) => {
  const { id } = props;
  const [currentPagination, setCurrentPagination] = useState(1);
  const { pagination, pageParams } = usePagination({
    pageSize: FILESTORAGE_HISTORY_PAGE_SIZE,
    current: currentPagination,
  });

  const { spaceXS, spaceM, spaceL } = useSpace();

  useUnmount(() => fileStorageEntryHistoryStorage.storage.resetStoreEvent);

  const {
    data: fileStorageEntryHistoryData,
    loading: isFileStorageEntryHistoryLoading,
    error: fileStorageEntryHistoryError,
    pagination: { count },
  } = useAbstractStorage(fileStorageEntryHistoryStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id, ...pageParams },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const isAllPagesShowed = fileStorageEntryHistoryData.length === count;

  const onDownloadMore = () => {
    if (pagination.current && !isAllPagesShowed) {
      setCurrentPagination((prev) => prev + 1);
    }
  };

  const isHistoryEmpty =
    !isFileStorageEntryHistoryLoading &&
    fileStorageEntryHistoryData.length === 0 &&
    !fileStorageEntryHistoryError;

  const isShowData = !fileStorageEntryHistoryError || !isHistoryEmpty;
  const { lg: isLayoutLarge } = useBreakpoint();

  return (
    <UiCard emptyPadding size="default" emptyRadius={!isLayoutLarge}>
      {isLayoutLarge && (
        <UiCard.Header style={{ padding: spaceL }}>
          <UiCard.Header.Title>История изменений</UiCard.Header.Title>
        </UiCard.Header>
      )}
      <UiCard.Content style={{ padding: '0 20px 20px' }}>
        {!isShowData && (
          <UiEmpty.Feed
            emptyMessage={{ header: isHistoryEmpty ? 'Нет истории' : 'Ошибка получения истории' }}
          />
        )}
        {isShowData && (
          <UiSpace size={spaceM} direction="vertical" full>
            <UiDescriptions gutter={[0, spaceM]}>
              {isFileStorageEntryHistoryLoading
                ? historyLoadingMock
                : fileStorageEntryHistoryData.map(
                    ({
                      summary: actionSummary,
                      actor,
                      object: { summary: objectSummary, value: objectValue },
                      createdAt,
                    }) => (
                      <UiDescriptions.Item key={createdAt} spaceProps={{ size: 4, full: true }} span={24}>
                        <UiSpace>
                          <UiTypography.Text>
                            <UiTruncateMarkup lines={2} truncate>
                              {actionSummary} {objectSummary}
                            </UiTruncateMarkup>
                          </UiTypography.Text>
                          <UiTypography.Text strong>
                            <UiTruncateMarkup lines={1} truncate>
                              {objectValue}
                            </UiTruncateMarkup>
                          </UiTypography.Text>
                        </UiSpace>
                        <UiSpace>
                          {actor && (
                            <UserLinkCell
                              type="secondary"
                              lines={1}
                              size={24}
                              user={actor}
                              nameStyles={{ marginLeft: spaceXS }}
                            />
                          )}
                          <UiTypography.Text type="secondary">
                            {getFormattedDate(createdAt, 'dateTime')}
                          </UiTypography.Text>
                        </UiSpace>
                      </UiDescriptions.Item>
                    ),
                  )}
            </UiDescriptions>
            {!isAllPagesShowed && (
              <UiButton type="link" onClick={onDownloadMore} label="Показать более ранние изменения" />
            )}
          </UiSpace>
        )}
      </UiCard.Content>
    </UiCard>
  );
};
