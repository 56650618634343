import {
  CkEditorConfig,
  EditorExtraButton,
  allEditorToolbars,
  mergeEditorToolbar,
  Post,
  PostFooterAction,
  PostFormFields,
} from '@vkph/components';
import { UiDivider, UiFlex, UiFormInstance, UiSpace, message, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, Fragment, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import { uploadFileStorageMultipleAttachmentsEffectFactory } from '@vkph/common/store/filestorage';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import {
  BasicPostAttachmentModel,
  BlogModerationType,
  PermissionsV2Enum,
  PostBasicModel,
  PostSettings,
  PostSettingsVisibilityType,
  PostTypes,
} from '@vkph/common/types/models';
import { generateTagApiOptions, getErrorResponseMessage } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { createPostEffect, updatePostEffect } from '../../../../store/post';
import { CreatePostParams, UpdatePostParams } from '../../../../store/post/api';
import {
  OnAttachmentRemoveParams,
  OnAttachmentUpdateParams,
  PostAttachmentsAdapter,
} from '../../attachments/adapter';
import type { PostItemProps } from '../PostCompound';
import { getAttachmentActions, isSingleAttachmentType } from './helpers';

interface Props extends Omit<PostItemProps, 'onPin' | 'post' | 'postsStorage'> {
  postType: PostTypes;
  post?: PostBasicModel;
  onCancel?: () => void;
  attachments?: BasicPostAttachmentModel[];
  onSuccess?: () => void;
  isShowSettingsVisibility?: boolean;
  postSettingsWrapper?: FC<PropsWithChildren>;
}

const LEGACY_POST_TITLE_EMPTY = '';

export const PostCompoundEdit: FC<Props> = (props) => {
  const {
    post,
    postType,
    blog,
    onCancel,
    onSuccess,
    isShowSettingsVisibility,
    attachments = [],
    postSettingsWrapper: PostSettingsWrapper = Fragment,
  } = props;
  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const [postSetting, setPostSettings] = useState<PostSettings>(
    post?.settings || {
      isReacted: true,
      isComments: true,
      visibility: PostSettingsVisibilityType.Followers,
    },
  );
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceS, spaceM, spaceXL } = useSpace();
  const { spaceXL: spaceXLpx, spaceM: spaceMpx } = useSpace({ unit: 'px' });
  const horizontalPadding = isLayoutLarge ? spaceXL : spaceM;

  const [postAttachments, setPostAttachments] = useState<BasicPostAttachmentModel[]>(attachments);
  const formRef = useRef<UiFormInstance>(null);
  const uploadMultipleFilesEffect = useMemo(uploadFileStorageMultipleAttachmentsEffectFactory, []);

  const isUpdateFetching = useStore(updatePostEffect.pending);
  const isCreateFetching = useStore(createPostEffect.pending);
  const isFetching = isUpdateFetching || isCreateFetching;

  const onAttachmentUpdate = ({
    attachmentId,
    attachmentType,
    attachment: attachedEntity,
  }: OnAttachmentUpdateParams) => {
    setPostAttachments((attachmentsState) => {
      const filteredAttachments = attachmentsState.filter((attachment) => {
        return isSingleAttachmentType(attachmentType)
          ? attachment.attachmentType !== attachmentType
          : attachment.attachmentId !== attachmentId;
      });

      return [
        ...filteredAttachments,
        {
          attachmentId,
          attachmentType,
          attachedEntity,
        },
      ];
    });
  };

  const onAttachmentRemove = useCallback(({ attachmentId, attachmentType }: OnAttachmentRemoveParams) => {
    setPostAttachments((attachmentsState) => {
      return attachmentsState.filter((attachment) => {
        const isAttachmentToRemove =
          attachment.attachmentId === attachmentId && attachmentType === attachment.attachmentType;

        return !isAttachmentToRemove;
      });
    });
  }, []);

  const editorAttachmentButtons = useMemo<EditorExtraButton[]>(
    () =>
      getAttachmentActions(uploadMultipleFilesEffect, onAttachmentUpdate).map((actions) => ({
        ...actions,
        id: `extra-button-${actions.type}`,
        onClick: () => {
          actions.onClick?.();
        },
      })),
    [getAttachmentActions],
  );

  const editorAttachmentButtonsConfig = useMemo<Partial<CkEditorConfig>>(() => {
    return {
      toolbar: {
        items: mergeEditorToolbar([...allEditorToolbars, editorAttachmentButtons.map((button) => button.id)]),
      },
      extraButtons: {
        items: editorAttachmentButtons,
      },
    };
  }, [editorAttachmentButtons]);

  const controls = useMemo<PostFooterAction[]>(() => {
    let confirmLabel = 'Опубликовать пост';

    const isModerator = blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts];
    const isSuggestPost = blog?.moderationType === BlogModerationType.Premoderation && !isModerator;

    if (post) {
      confirmLabel = 'Сохранить';
    }

    if (isSuggestPost) {
      confirmLabel = 'Предложить пост';
    }

    return [
      {
        label: confirmLabel,
        onClick: () => {
          formRef?.current?.submit();
        },
        type: 'primary',
        disabled: false,
        loading: isFetching,
      },
      {
        label: 'Отмена',
        onClick: () => {
          onCancel?.();
          setPostAttachments(attachments);
        },
        type: 'secondary',
      },
    ];
  }, [post, formRef?.current, onCancel, blog, isFetching]);

  const onSavePost = (postFields: PostFormFields) => {
    const commonData = {
      ...postFields,
      tags: generateTagApiOptions(postFields.tags),
      settings: postSetting,
      postType,
      entitiesToAttach: postAttachments.map((attachment) => ({
        attachmentType: attachment.attachmentType,
        attachmentId: attachment.attachmentId,
      })),
      title: LEGACY_POST_TITLE_EMPTY,
    };

    if (post) {
      const postData: UpdatePostParams = {
        ...postFields,
        ...commonData,
        postId: post?.id,
      };

      updatePostEffect(postData)
        .then(onCancel)
        .catch((e) => message.error(getErrorResponseMessage(e, 'Нет прав на изменение поста')));
    } else {
      const postData: CreatePostParams = {
        ...postFields,
        ...commonData,
        blogId: blog?.id,
        ...(!blog?.id && { toUser: profileFullInfoData?.main?.id }),
      };

      createPostEffect(postData)
        .then(onSuccess)
        .catch(() => message.error('Не удалось создать пост'));
    }
  };

  return (
    <Post style={{ padding: `${isLayoutLarge ? spaceXLpx : spaceMpx} 0` }}>
      <Post.Body padding={[0, horizontalPadding]}>
        <UiSpace size={isLayoutLarge ? 'large' : 'middle'} full direction="vertical">
          <Post.Body.Form
            onSubmit={onSavePost}
            post={post}
            ref={formRef}
            editorExtraConfig={editorAttachmentButtonsConfig}
          />

          {postAttachments.length > 0 && (
            <Post.Body.Attachments
              attachments={postAttachments}
              renderItem={(item) => (
                <PostAttachmentsAdapter
                  isEdit
                  value={item}
                  onUpdate={onAttachmentUpdate}
                  onRemove={onAttachmentRemove}
                />
              )}
            />
          )}
        </UiSpace>
      </Post.Body>
      <UiDivider />
      <Post.Footer padding={[0, horizontalPadding]}>
        <UiFlex justify="space-between" align="center" gap={spaceS} wrap>
          <Post.Footer.Actions actions={controls} />
          <PostSettingsWrapper>
            <Post.Footer.Settings
              postType={postType}
              settings={postSetting}
              isShowSettingsVisibility={isShowSettingsVisibility}
              onSettingsChange={setPostSettings}
            />
          </PostSettingsWrapper>
        </UiFlex>
      </Post.Footer>
    </Post>
  );
};
