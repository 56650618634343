import { Post, PostBodyMarkupProps, PostFooterAction, PostSwitchAction } from '@vkph/components';
import {
  UiButton,
  UiCollapse,
  UiDivider,
  UiRender,
  UiRenderType,
  UiRow,
  UiSpace,
  UiTag,
  UiTypography,
  UiTypographySymbolName,
  message,
  useBreakpoint,
  useModalBase,
  usePaddingStyle,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { AttachmentsStorage } from '@vkph/common/store/attachment';
import { reactionEffect } from '@vkph/common/store/reactions';
import { postReactionsContentTypeMap } from '@vkph/common/store/reactions/constants';
import { LongreadTypes } from '@vkph/common/types/longread';
import {
  EmojiUuid,
  PermissionsV2Enum,
  PostAttachmentModel,
  PostStatuses,
  PostTypes,
} from '@vkph/common/types/models';
import { capitalizeFirstLetter, getErrorResponseMessage } from '@vkph/common/utils';
import { getPostBlogsMap, isSomePostBlogsNegativeKey } from '@vkph/components/utils';
import { useSpace } from '@vkph/ui/hooks';

import { deletePostEffect, switchPinPostEffect } from '../../../../store/post';
import { getCommentsContentTypeByPost } from '../../../comment/helpers';
import { OnAttachmentUpdateParams, PostAttachmentsAdapter } from '../../attachments/adapter';
import { PostItemProps } from '../PostCompound';
import { PostCompoundCommentsWithInput } from '../comments-with-input/PostCompoundCommentsWithInput';

interface Props extends Pick<PostItemProps, 'blog' | 'post' | 'postsStorage' | 'onPin'> {
  switchEditMode?: () => void;
  attachments: PostAttachmentModel[];
  attachmentsStorage: AttachmentsStorage;
}

export const PostCompoundRepresentation: FC<Props> = (props) => {
  const { post, blog, postsStorage, onPin, switchEditMode, attachments, attachmentsStorage } = props;
  const { id: postId, type: postType, tags } = post;
  const { reloadAttachmentByIdEffect } = attachmentsStorage;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceXL, spaceM } = useSpace();
  const { warning } = useModalBase();

  const { switchFavoritePostEffect, changePostStatusEffect, updateCommentsCountEvent } = postsStorage;

  const isPostRestorePending = useStore(changePostStatusEffect.pending);

  const isPostStatus = (status: PostStatuses) => post.flag === status;
  const isPremoderation = isPostStatus(PostStatuses.AwaitingApprove);
  const isDeclined = isPostStatus(PostStatuses.Decline);
  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const showComments = !isDeclined && !isCommentsDisabled && !isPremoderation && isLayoutLarge;
  const showReactions = !isPremoderation;
  const isDeletePostPending = useStore(deletePostEffect.pending);
  const isChangePostPending = useStore(changePostStatusEffect.pending);
  const { openLongread } = useLongreadNavigation();

  const onOpenLongread: PostBodyMarkupProps['onClick'] = () => {
    openLongread({
      postType: post.type,
      id: post.id,
      type: LongreadTypes.Post,
    });
  };

  const onPostDelete = useCallback(() => {
    const postName = postType === PostTypes.Micropost ? 'микропост' : 'пост';

    warning({
      title: `Удалить ${postName}`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      disabled: isDeletePostPending,
      onOk: async (confirmClose) => {
        try {
          await deletePostEffect({ postId, postType });
          message.success(`${capitalizeFirstLetter(postName)} удален`);
          // Todo выпилить проверку в B2BCORE-8054
          if (postType === PostTypes.Entry) postsStorage.resetAndRefetchEvent();
          confirmClose();
        } catch (error) {
          message.error(getErrorResponseMessage(error, `Ошибка удаления ${postName}`));
        }
      },
    });
  }, [deletePostEffect, postId, postType]);

  const onPostFavorite: PostSwitchAction = useCallback(
    (isFavorite) => {
      switchFavoritePostEffect({ postId, postType, favorite: isFavorite }).catch((e) =>
        message.error(getErrorResponseMessage(e, 'Не удалось добавить пост в избранное')),
      );
    },
    [postId, postType, switchFavoritePostEffect],
  );

  const onPostPin: PostSwitchAction = useCallback(
    (isPinned) => {
      const errorMessage = `Не удалось ${isPinned ? 'закрепить' : 'открепить'} пост`;

      switchPinPostEffect({ postId, isPinned })
        .then(onPin)
        .catch((error) => getErrorResponseMessage(error, errorMessage));
    },
    [postId, switchPinPostEffect],
  );

  const onPostCommentsCountUpdate = useCallback(
    (commentsCount: number) => updateCommentsCountEvent({ postId, postType, commentsCount }),
    [postId, postType, updateCommentsCountEvent],
  );

  const onPublish = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.FlagPublished })
      .then(() => {
        postsStorage.resetAndRefetchEvent();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось опубликовать пост')));
  }, [postId, postType]);

  const onDecline = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.Decline }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось отклонить пост')),
    );
  }, [postId, postType]);

  const onCancelDecline = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.AwaitingApprove }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось восстановить пост')),
    );
  }, [postId, postType]);

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      const contentType = postReactionsContentTypeMap[postType];

      if (contentType) {
        reactionEffect({ objectId: postId, contentType, emojiUuid }).catch((e) =>
          message.error(
            getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
          ),
        );
      }
    },
    [postId, postType],
  );

  const onAttachmentReload = useCallback(
    ({ attachmentId }: OnAttachmentUpdateParams) => reloadAttachmentByIdEffect({ attachmentId }),
    [reloadAttachmentByIdEffect],
  );

  const postActions = useMemo<PostFooterAction[]>(() => {
    const actions: PostFooterAction[] = [];

    if (isPremoderation && blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]) {
      actions.push({
        label: 'Опубликовать',
        onClick: onPublish,
        type: 'primary',
        disabled: isChangePostPending,
      });

      actions.push({
        label: 'Отклонить',
        onClick: onDecline,
        type: 'tertiary',
        disabled: isChangePostPending,
      });
    }

    return actions;
  }, [post, onPublish, onDecline, isPremoderation, blog, isChangePostPending]);

  const postBlogs = useMemo(() => getPostBlogsMap(post), [post]);
  const commentsType = useMemo(() => getCommentsContentTypeByPost(post), [post]);
  const isActiveAuthor = Boolean(post.author.isActive || post.author.keycloakUser?.isActive);
  const showActions = postActions.length > 0;
  const showFooter = showActions || showComments;
  const postPadding = isLayoutLarge ? spaceXL : spaceM;
  const postActionsPadding = usePaddingStyle([spaceM, spaceXL]);

  return (
    <Post style={{ paddingTop: postPadding }}>
      <Post.Header
        padding={[0, postPadding]}
        blogs={postBlogs}
        author={post.author}
        postDate={post.publishedAt || post.createdAt}
      >
        <Post.Header.Actions
          blog={blog}
          post={post}
          onEdit={switchEditMode}
          onDelete={onPostDelete}
          onFavorite={onPostFavorite}
          onPin={onPostPin}
        />
      </Post.Header>
      <Post.Body padding={[spaceM, postPadding]}>
        {isDeclined && (
          <UiRow>
            <UiTypography.Text>Запись удалена.</UiTypography.Text>
            <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
            {blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts] && (
              <UiButton
                disabled={isPostRestorePending}
                type="link"
                style={{ display: 'inline' }}
                onClick={onCancelDecline}
              >
                Восстановить
              </UiButton>
            )}
          </UiRow>
        )}

        {!isDeclined && (
          <UiSpace size={isLayoutLarge ? 'large' : 'middle'} full direction="vertical">
            <UiCollapse.Content compact={!isLayoutLarge}>
              <Post.Body.Markup post={post} onClick={onOpenLongread} />
            </UiCollapse.Content>

            {tags.length > 0 && <UiTag.Group tags={tags} />}

            {attachments.length > 0 && (
              <Post.Body.Attachments
                attachments={attachments}
                renderItem={(item) => <PostAttachmentsAdapter value={item} onUpdate={onAttachmentReload} />}
              />
            )}

            {showReactions && (
              <Post.Body.Reactions
                post={post}
                {...(isActiveAuthor && {
                  onReaction,
                })}
              />
            )}
          </UiSpace>
        )}
      </Post.Body>
      <UiRender type={UiRenderType.NullElement} visible={showFooter}>
        <UiDivider emptyMargin />
        <Post.Footer>
          {showActions && <Post.Footer.Actions style={postActionsPadding} actions={postActions} />}
          {showComments && (
            <PostCompoundCommentsWithInput
              objectId={post.id}
              showInput={isActiveAuthor}
              contentType={commentsType}
              onCommentsCountUpdate={onPostCommentsCountUpdate}
            />
          )}
        </Post.Footer>
      </UiRender>
    </Post>
  );
};
