import { Layout, UiFlex } from '@vkph/ui';
import React, { FC } from 'react';

import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';

import { FileActions } from '../../actions';
import { FileHistory } from '../../history';
import { FileInfoCard } from '../../info-card';
import { FileInfoCardGeneral } from '../../info-card/general';
import { FileVersions } from '../../versions';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileId: FileStorageEntryId;
  fileStorageRootId?: FileStorageEntryId;
};

export const FileViewDesktop: FC<Props> = (props) => {
  const { fileInfoStorage, fileId, fileStorageRootId } = props;
  const { spaceL } = useSpace();

  return (
    <>
      <Layout.Content>
        <FileInfoCard fileInfoStorage={fileInfoStorage} fileStorageRootId={fileStorageRootId} />
        <FileVersions id={fileId} fileInfoStorage={fileInfoStorage} />
      </Layout.Content>
      <Layout.Sider breakpoint="lg">
        <UiFlex gap={spaceL} vertical>
          <FileInfoCardGeneral fileInfoStorage={fileInfoStorage} id={fileId} />
          <FileActions fileInfoStorage={fileInfoStorage} />
          <FileHistory id={fileId} />
        </UiFlex>
      </Layout.Sider>
    </>
  );
};
