import { ColumnsType, TableData, TableDataCells, UiButton, UiIcon, UiTypography, message } from '@vkph/ui';
import classNames from 'classnames';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  getMinutes,
  isToday,
  setMinutes,
  startOfHour,
} from 'date-fns';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers';
import { fetchEventEffect } from '@vkph/common/store/calendar/event';
import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import {
  CalendarAttendeeToAddList,
  CalendarBusyInterval,
  CalendarUserStatus,
  UserIdModel,
} from '@vkph/common/types/models';
import {
  authService,
  getFormattedDate,
  getFullNameWithoutPatronymic,
  getWorkingDaySinceTillDateTime,
} from '@vkph/common/utils';
import CalendarAddSvg from '@vkph/ui/svg/calendar-add.svg';

import { getBusySlotsStorage } from '../../store/busy-slots';
import { SlotsTable } from '../slots-table/SlotsTable';
import styles from './ProfileTimeslots.scss';
import { ProfileWeekTimeline } from './ProfileWeekTimeline/ProfileWeekTimeline';
import { ProfileTimeslotsDatePicker } from './date-picker/ProfileTimeslotsDatePicker';

const DISPLAYED_SINCE_HOUR = 9;
const DISPLAYED_TILL_HOUR = 22;
const INITIAL_NEW_MEETING_DURATION_MINUTES = 60;
const ADD_MEETING_STEP_MINUTES = 30;

const getStartDate = (start: Date) => {
  const startMinutes = start.getMinutes();

  if (startMinutes === 0) return start;

  return startMinutes > 30 ? startOfHour(addHours(start, 1)) : setMinutes(start, 30);
};

const getEndDate = (end: Date) => {
  const endMinutes = end.getMinutes();

  return endMinutes < 30 ? startOfHour(end) : setMinutes(end, 30);
};

type ProfileTimeslotsParams = {
  slotsTableClassName?: string;
  showCurrentTime?: boolean;
  customModalSection?: React.FC<React.HTMLAttributes<HTMLDivElement>>;
  formClassName?: string;
  isUserActive?: boolean;
};

export const ProfileTimeslots = (props: ProfileTimeslotsParams) => {
  const currentUserData = useCurrentProfile();
  const busySlotsStorage = useMemo(
    () => getBusySlotsStorage({ isSubscribedOnCreateUpdateFromHeader: true }),
    [],
  );

  const { slotsTableClassName, showCurrentTime, customModalSection, formClassName, isUserActive } = props;
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();

  const tableRef = useRef<HTMLSpanElement | null>(null);

  const [cellWidth, setCellWidth] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { sinceDate, tillDate, displayedDurationHours } = getWorkingDaySinceTillDateTime(
    selectedDate,
    DISPLAYED_SINCE_HOUR,
    DISPLAYED_TILL_HOUR,
  );

  const { data: profileData } = useStore(profileFullInfoStorage.storage.store);
  const since = sinceDate.toISOString();
  const till = tillDate.toISOString();

  const { data: busySlots, refetchWithLastParams: busySlotsRefetch } = useAbstractStorage(
    busySlotsStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userIds: [userId],
        since,
        till,
      },
    },
  );

  const onCreateMeetingClick = (meetingSinceDate?: Date, meetingTillDate?: Date) => {
    if (currentUserData) {
      const { avatar, email: currentUserEmail } = currentUserData;
      const authUserId = authService.getCurrentUserId();

      const attendees: CalendarAttendeeToAddList = [
        {
          userId: authUserId || '',
          status: CalendarUserStatus.Accepted,
          image: avatar,
          mailto: currentUserEmail || '',
        },
      ];

      if (profileData?.main?.id === userId && authUserId !== userId) {
        const {
          main: { smallAvatar, fullName, email },
          job: { position },
        } = profileData;

        attendees.push({
          userId,
          status: CalendarUserStatus.NeedAction,
          fullName: getFullNameWithoutPatronymic(fullName),
          image: smallAvatar,
          position,
          mailto: email || '',
        });
      }

      const defaultSinceDate = addHours(startOfHour(selectedDate), 1);
      const defaultTillDate = addHours(defaultSinceDate, 1);

      openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
        initialEventFormValues: {
          attendees,
          sinceDate: meetingSinceDate || defaultSinceDate,
          tillDate: meetingTillDate || defaultTillDate,
        },
        customSection: customModalSection,
        formClassName,
        onSuccess: busySlotsRefetch,
      });
    }
  };

  const updateCellWidth = useCallback(() => {
    if (tableRef.current) {
      const tableWidth = tableRef.current.getClientRects().item(0)?.width || 0;

      setCellWidth(tableWidth / (displayedDurationHours * 2));
    }
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      updateCellWidth();
    }

    window.addEventListener('resize', updateCellWidth);

    return () => {
      window.removeEventListener('resize', updateCellWidth);
    };
  }, [tableRef.current]);

  const hoursColumns = useMemo(() => {
    const result = [];

    for (let i = 0; i < displayedDurationHours * 2; i++) {
      const columnSinceDate = addMinutes(sinceDate, i * 30);

      result.push(columnSinceDate);
    }

    return result;
  }, [sinceDate]);

  const getSlotWidth = (minutes: number) => Math.floor((minutes * cellWidth - 1) / 30);

  const slotsData: TableDataCells = {};

  const addFreeIntervalButtons = (start: Date, end: Date) => {
    const startDate = getStartDate(start);
    const endDate = getEndDate(end);
    const intervalDurationInMinutes = differenceInMinutes(endDate, startDate);

    if (intervalDurationInMinutes > 0) {
      const buttonIndex = getFormattedDate(startDate, 'time');
      const isDurationShortened = intervalDurationInMinutes <= INITIAL_NEW_MEETING_DURATION_MINUTES;

      const meetingEndDate = !isDurationShortened
        ? addMinutes(startDate, INITIAL_NEW_MEETING_DURATION_MINUTES)
        : endDate;
      const tillTime = getFormattedDate(meetingEndDate, 'time');

      const tooltip = `${buttonIndex} – ${tillTime}`;

      const emptySlotButtonWidth = getSlotWidth(
        isDurationShortened ? intervalDurationInMinutes : INITIAL_NEW_MEETING_DURATION_MINUTES,
      );

      slotsData[buttonIndex] = (
        <SlotsTable.Slot
          tooltip={tooltip}
          top={8}
          width={emptySlotButtonWidth}
          type="free"
          onClick={() => onCreateMeetingClick(startDate, meetingEndDate)}
        />
      );

      const nextStartDate = addMinutes(startDate, ADD_MEETING_STEP_MINUTES);

      addFreeIntervalButtons(nextStartDate, endDate);
    }
  };

  let previousDate = sinceDate;

  const onEditMeetingClick = (calendarEventId: string) => {
    fetchEventEffect(calendarEventId)
      .then((event) => {
        openGlobalModal(GlobalModalNames.CalendarEventDetail, {
          event,
          onSuccess: busySlotsRefetch,
        });
      })
      .catch(() => message.error('При открытии встречи возникла ошибка'));
  };

  const getIntervals = (intervals: CalendarBusyInterval[]) => {
    if (!intervals.length) {
      addFreeIntervalButtons(sinceDate, tillDate);
    } else {
      intervals.forEach((slot, index) => {
        const slotSinceDate = new Date(slot.since);
        const slotTillDate = new Date(slot.till);

        const slotSinceDateOnSelectedDay = slotSinceDate > sinceDate ? slotSinceDate : sinceDate;
        const slotTillDateOnSelectedDay = slotTillDate < tillDate ? slotTillDate : tillDate;
        const minutesDuration = differenceInMinutes(slotTillDateOnSelectedDay, slotSinceDateOnSelectedDay);
        const width = getSlotWidth(minutesDuration);

        if (index === 0) {
          addFreeIntervalButtons(previousDate, slotSinceDateOnSelectedDay);
          previousDate = slotTillDate;
        }

        const nextMeeting = intervals[index + 1];
        const nextMeetingSinceDate = nextMeeting ? new Date(nextMeeting.since) : tillDate;
        const nextMeetingTillDate = nextMeeting ? new Date(nextMeeting.till) : tillDate;

        addFreeIntervalButtons(previousDate, nextMeetingSinceDate);
        previousDate = nextMeetingTillDate;

        const minutes = getMinutes(slotSinceDateOnSelectedDay);
        let slotKeyDate = slotSinceDateOnSelectedDay;

        if (minutes >= 30) {
          slotKeyDate = setMinutes(slotSinceDateOnSelectedDay, 30);
        } else {
          slotKeyDate = setMinutes(slotSinceDateOnSelectedDay, 0);
        }

        const shift = Math.floor(
          (differenceInMinutes(slotSinceDateOnSelectedDay, slotKeyDate) * cellWidth) / 30,
        );

        const dataIndex = getFormattedDate(slotKeyDate, 'time');
        const sinceText = getFormattedDate(slotSinceDate, isToday(slotSinceDate) ? 'time' : 'dateTime');
        const tillText = getFormattedDate(slotTillDate, isToday(slotTillDate) ? 'time' : 'dateTime');
        const tooltip = `${sinceText} – ${tillText}`;

        const onTableSlotClick = () => {
          if (slot.calendarEventId) {
            onEditMeetingClick(slot.calendarEventId);
          }
        };

        slotsData[dataIndex] = (
          <SlotsTable.Slot
            onClick={onTableSlotClick}
            tooltip={tooltip}
            top={8}
            shift={shift}
            width={width}
            type="busy"
          />
        );
      });
    }

    return slotsData;
  };

  const timelineColumns: ColumnsType<TableData> = useMemo(
    () =>
      hoursColumns.map((columnDate, index) => {
        const isEven = index % 2 === 0;
        const className = classNames(styles.profileTimeslots__cell, {
          [styles.profileTimeslots__cell_solid]: isEven,
          [styles.profileTimeslots__cell_dashed]: !isEven,
        });
        const dataIndex = getFormattedDate(columnDate, 'time');

        return {
          key: dataIndex,
          width: cellWidth,
          title: (
            <UiTypography.Text className={styles.profileTimeslots__hour}>
              {format(columnDate, 'HH')}
            </UiTypography.Text>
          ),
          dataIndex,
          colSpan: isEven ? 2 : 0,
          align: 'left',
          className,
        };
      }),
    [hoursColumns],
  );

  const getTableData = () => {
    const userSlots = busySlots.find((slot) => slot.userId === userId);
    const loadedIntervals = profileData && userSlots ? userSlots.intervals : [];
    const slots = getIntervals(loadedIntervals);

    return [
      {
        key: userId,
        ...slots,
      },
    ];
  };

  const data = useMemo(getTableData, [cellWidth, busySlots, userId, profileData]);

  return (
    <div className={styles.profileTimeslots}>
      <span className={styles.profileTimeslots__date}>
        <ProfileTimeslotsDatePicker
          date={selectedDate}
          onDateChange={setSelectedDate}
          isUserActive={isUserActive}
        />
      </span>
      <span className={classNames(styles.profileTimeslots__table, slotsTableClassName)} ref={tableRef}>
        <SlotsTable
          dataSource={data}
          columns={timelineColumns}
          scroll={undefined}
          sticky={false}
          isUserActive={isUserActive}
        />
        {showCurrentTime && isToday(selectedDate) && (
          <ProfileWeekTimeline hourStart={DISPLAYED_SINCE_HOUR} hourEnd={DISPLAYED_TILL_HOUR} />
        )}
      </span>
      <UiButton
        type="primary"
        icon={<UiIcon component={CalendarAddSvg} width={20} height={20} />}
        label="Организовать встречу"
        size="large"
        onClick={() => onCreateMeetingClick()}
        disabled={!isUserActive}
      />
    </div>
  );
};
