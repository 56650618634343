import { LinkListItem } from '@vkph/components';
import { MAX_BADGE_COUNT, UiBadge, UiButton, UiInput, UiModal, UiModalSteps } from '@vkph/ui';
import React, { FC, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GlobalModalNames, GlobalModalsStorePayloads } from '@vkph/common/store/global-modals';
import { getSkillPeoplesStorage, getSkillsStorage } from '@vkph/common/store/profile/skills';
import { ApproverUserSkill, PeopleModel, SkillsUsersSteps, UserIdModel } from '@vkph/common/types/models';
import { RouteNames, createArrayMock, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { skillsTextMap } from '../../constants';
import styles from './SkillsUsersModal.scss';

const MOCKS_COUNT = 3;

type UserInList = PeopleModel | ApproverUserSkill;
type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.SkillsUsers]['payload']>;

export const SkillsUsersModal: FC<Props> = (props) => {
  const { userId, userSkill, skillType, step = SkillsUsersSteps.Possess } = props;

  const [searchFilters, setSearchFilters] = useState('');
  const [currentStep, setCurrentStep] = useState(step);
  const [confirmedSkillToUser, setConfirmedSkillToUser] = useState<UserIdModel>(userId);

  const { skill } = userSkill;
  const { name, id: skillId } = skill;

  const isActiveStep = (key: SkillsUsersSteps) => key === currentStep;

  const peoplesSkillStorage = useMemo(() => getSkillPeoplesStorage({ skillId, skillType }), [skillId]);

  const { data: peopleSkillData, loading: peopleSkillLoading } = useAbstractStorage(
    peoplesSkillStorage.storage,
    {
      autoFetchAndRefetch: Boolean(skillId),
      autoFetchParams: { skillId, skillType },
    },
  );

  const skillStorage = useMemo(
    () => getSkillsStorage({ userId: confirmedSkillToUser, skillType }),
    [confirmedSkillToUser, skillType],
  );

  const { data: skillsList, loading: skillsLoading } = useAbstractStorage(skillStorage.storage, {
    autoFetchAndRefetch: Boolean(confirmedSkillToUser),
    autoFetchParams: { userId: confirmedSkillToUser, skillType },
    resetStoreOnUnmount: true,
  });

  const userUseSkillApprovers = useMemo(
    () =>
      skillsList.find((item) => item.skill.id === skillId)?.approvers?.map((approver) => approver.userFrom) ||
      [],
    [skillsList, skillId],
  );

  const peopleData = useMemo(
    () => (isActiveStep(SkillsUsersSteps.Possess) ? peopleSkillData : userUseSkillApprovers),
    [isActiveStep, skillType, userUseSkillApprovers],
  );

  const setSearchFiltersDebounced = useDebouncedCallback((values: string) => setSearchFilters(values), 300);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFiltersDebounced(e.target.value);
  };

  // TODO: реализовать серверный поиск по готовности api
  const filteredPeopleData = useMemo(() => {
    const search = searchFilters.toLowerCase();

    return (peopleData as UserInList[]).filter(
      ({ fullName: { firstName, lastName } }) =>
        firstName.toLowerCase().includes(search) || lastName.toLowerCase().includes(search),
    );
  }, [peopleData, searchFilters]);

  const filteredPeopleCount = filteredPeopleData.length || null;

  const titleSteps: UiModalSteps = {
    1: {
      title: `Обладают ${skillsTextMap[skillType].ThanDown} "${name}"`,
      badgeValue: filteredPeopleCount,
    },
    2: {
      title: `Подтвердили ${skillsTextMap[skillType].OneDown} "${name}"`,
      badgeValue: filteredPeopleCount,
    },
  };

  const SuffixedChildren = ({ user }: { user: UserInList }) => {
    const { id } = user;
    const approvers = 'approvers' in user ? user.approvers : 0;

    return (
      <>
        <UiButton
          type="link"
          disabled={!approvers}
          onClick={() => {
            setCurrentStep(SkillsUsersSteps.Confirmed);
            setConfirmedSkillToUser(id);
          }}
        >
          Подтвердили
        </UiButton>
        <UiBadge
          showZero
          count={approvers}
          overflowCount={MAX_BADGE_COUNT}
          className={styles.skillsUsersModal__suffixedBadge}
        />
      </>
    );
  };

  const peopleLoading = peopleSkillLoading || skillsLoading;

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={titleSteps} currentStep={currentStep} onStepBack={setCurrentStep} />
      </UiModal.Header>
      <UiModal.Content>
        <div className={styles.skillsUsersModal__section}>
          <UiInput.Search size="large" placeholder="Поиск" onChange={onChangeSearch} />
        </div>
        {peopleLoading && createArrayMock(MOCKS_COUNT, (_, key) => <LinkListItem.Skeleton key={key} />)}
        {!peopleLoading &&
          filteredPeopleData.map((item) => {
            return (
              <LinkListItem
                to={getRoutePath(RouteNames.Profile, { id: item.id })}
                suffixedChildren={
                  currentStep === SkillsUsersSteps.Possess ? <SuffixedChildren user={item} /> : <></>
                }
                key={item.id}
                avatarProps={{ src: item.avatar }}
                title={getFullNameWithoutPatronymic(item.fullName)}
                subtitle={item.job.position?.name}
              />
            );
          })}
      </UiModal.Content>
    </>
  );
};
