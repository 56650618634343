import { ActionsDropdown } from '@vkph/components';
import { UiAvatar, UiCard, UiCol, UiInput, UiRow, useSpace } from '@vkph/ui';
import React, { FC, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';

import { useCurrentProfile } from '@vkph/common/providers';
import { uploadFileStorageMultipleAttachmentsEffectFactory } from '@vkph/common/store/filestorage';
import { BasicPostAttachmentModel, BlogModerationType, PermissionsV2Enum } from '@vkph/common/types/models';
import { capitalizeFirstLetter, declenseFirstName } from '@vkph/common/utils';
import AttachSvg from '@vkph/ui/svg/attach.svg';

import { OnAttachmentUpdateParams } from '../../post/attachments/adapter';
import { AttachmentsAction, getAttachmentActions, PostCompound } from '../../post/compound';
import { WritePostProps } from '../WritePost';

const CLOSED_FORM_HEIGHT = 88;
const SHARED_USER_PLACEHOLDER = 'поделитесь чем-нибудь с коллегами';
const PROFILE_USER_PLACEHOLDER = 'напишите что-нибудь в ленте';

export const WritePostDesktop: FC<WritePostProps> = (props) => {
  const { postType, blog, isMainTimeline, postsStorage, profileInfo } = props;
  const uploadMultipleFilesEffect = useMemo(uploadFileStorageMultipleAttachmentsEffectFactory, []);
  const currentProfile = useCurrentProfile();
  const { spaceL } = useSpace();

  const currentUserName = currentProfile?.fullName?.firstName;
  const isCurrentUserProfile = currentProfile.id === profileInfo?.main?.id;

  const userPlaceholder = useMemo(() => {
    let value = capitalizeFirstLetter(SHARED_USER_PLACEHOLDER);
    const fromUserFirstName = currentProfile?.fullName?.firstName;
    const toUserFirstName =
      profileInfo?.main &&
      declenseFirstName({
        name: profileInfo.main.fullName.firstName,
        gender: profileInfo.main.gender,
      });

    if (!fromUserFirstName) {
      return value;
    }

    if (isCurrentUserProfile || !toUserFirstName) {
      value = `${capitalizeFirstLetter(SHARED_USER_PLACEHOLDER)}, ${fromUserFirstName}`;
    }

    if (!isCurrentUserProfile && toUserFirstName) {
      value = `${fromUserFirstName}, ${PROFILE_USER_PLACEHOLDER} ${toUserFirstName}`;
    }

    return value;
  }, [profileInfo, isCurrentUserProfile, currentUserName]);

  const isModerator = blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts];
  const isSuggestPost = blog?.moderationType === BlogModerationType.Premoderation && !isModerator;
  const placeholder = isSuggestPost ? 'Предложить пост' : userPlaceholder;

  const [attachments, setAttachments] = useState<BasicPostAttachmentModel[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const [ref, bounds] = useMeasure();
  const { size } = useSpring({
    from: { size: CLOSED_FORM_HEIGHT },
    size: isFocused ? bounds.height : CLOSED_FORM_HEIGHT,
  });

  const onAttachmentAdded = ({ attachmentId, attachmentType, attachment }: OnAttachmentUpdateParams) => {
    setAttachments([
      {
        attachmentId,
        attachmentType,
        attachedEntity: attachment,
      },
    ]);
    setIsFocused(true);
  };

  const attachmentActions = useMemo<AttachmentsAction[]>(
    () =>
      getAttachmentActions(uploadMultipleFilesEffect, onAttachmentAdded).map((actions) => ({
        ...actions,
        id: `extra-button-${actions.type}`,
        onClick: () => {
          actions.onClick?.();
        },
      })),
    [getAttachmentActions],
  );

  const onFormCancel = () => {
    setIsFocused(false);
    setAttachments([]);
  };

  const onFormSuccess = () => {
    onFormCancel();
    postsStorage?.resetAndRefetchEvent();
  };

  return (
    <UiCard size="default" emptyPadding style={{ marginBottom: isMainTimeline ? spaceL : undefined }}>
      <animated.div style={{ height: size }}>
        <UiCol ref={ref}>
          {isFocused && (
            <PostCompound.Edit
              blog={blog}
              postType={postType}
              onCancel={onFormCancel}
              attachments={attachments}
              isShowSettingsVisibility={isMainTimeline || isCurrentUserProfile}
              onSuccess={onFormSuccess}
            />
          )}
          {!isFocused && (
            <UiRow style={{ padding: 24 }} align="middle" wrap={false}>
              <UiCol>
                <UiAvatar size={40} src={currentProfile?.smallAvatar} />
              </UiCol>
              <UiCol flex="auto">
                <UiInput
                  noStyle
                  size="small"
                  variant="borderless"
                  onClick={() => setIsFocused(true)}
                  placeholder={placeholder}
                />
              </UiCol>
              <UiCol>
                <UiRow align="middle">
                  <ActionsDropdown icon={AttachSvg} items={attachmentActions} />
                </UiRow>
              </UiCol>
            </UiRow>
          )}
        </UiCol>
      </animated.div>
    </UiCard>
  );
};
