import {
  UiButton,
  UiCard,
  UiEmpty,
  UiIcon,
  UiList,
  UiModal,
  UiModalTypes,
  UiSkeleton,
  UiSpace,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import isToday from 'date-fns/isToday';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GlobalModalNames, closeGlobalModal, openGlobalModal } from '@vkph/common/store/global-modals';
import { getWidgetStorage } from '@vkph/common/store/pages';
import { WidgetModel } from '@vkph/common/types/models';
import { Note } from '@vkph/common/types/notes';
import {
  createArrayMock,
  getActualEditorFormat,
  getFormattedDate,
  getPlainTextFromHtml,
} from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import AddSvg from '@vkph/ui/svg/add.svg';
import NotesSvg from '@vkph/ui/svg/notes.svg';

import { WidgetModule } from '../../types';
import { NotesWidgetListModal } from './list-modal/NotesWidgetListModal';

const NOTES_LIST_LENGTH = 4;

interface Props extends WidgetModule {
  widget: WidgetModel;
}

export const NotesWidget: FC<Props> = (props) => {
  const { widget: widgetProp } = props;
  const { id: widgetId } = widgetProp;
  const [isOpenNotesList, setIsOpenNotesList] = useState(false);

  const { space2XS, spaceS, spaceM, spaceL } = useSpace();
  const { padding: notePadding } = usePaddingStyle([spaceS, spaceL]);
  const { padding: listPadding } = usePaddingStyle([spaceM, 0, 0]);

  const widgetStorage = useMemo(() => getWidgetStorage({ id: widgetId }), [widgetId]);
  const { data: widget, loading } = useAbstractStorage(widgetStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const widgetDataNotes: Note[] = widget?.attributes?.data?.notes || [];
  const isNotesListEmpty = !loading && !widgetDataNotes.length;

  const onSaveNote = useCallback(
    (newNote: Note) => {
      const notes = widgetDataNotes.filter((note) => note.id !== newNote.id);

      notes.unshift(newNote);

      return widgetStorage.updateWidgetEffect({
        widgetId,
        attributes: { ...widget?.attributes, data: { notes } },
        onlyUser: true,
      });
    },
    [widgetDataNotes],
  );

  const onToggleOpenNotesList = () => setIsOpenNotesList((value) => !value);

  const onOpenNoteEditor = useCallback(
    (note?: Note) =>
      openGlobalModal(GlobalModalNames.NoteEditor, {
        note,
        onSave: onSaveNote,
        onClose: () => closeGlobalModal(GlobalModalNames.NoteEditor),
      }),
    [onSaveNote],
  );

  const emptyNotesList = (
    <UiEmpty.Frame>
      <UiSpace size={space2XS} direction="vertical">
        <UiTypography.Text type="secondary">Заметки ещё не создавались</UiTypography.Text>
        <UiTypography.Link strong onClick={() => onOpenNoteEditor()}>
          Добавить заметку
        </UiTypography.Link>
      </UiSpace>
    </UiEmpty.Frame>
  );

  const Title = (
    <UiCard.Header
      hasBottomBorder
      action={
        !loading && (
          <UiButton
            type="link-secondary"
            onClick={() => onOpenNoteEditor()}
            icon={<UiIcon component={AddSvg} width={20} height={20} />}
          />
        )
      }
    >
      <UiCard.Header.Title
        icon={
          <UiIcon component={NotesSvg} width={24} height={24} color={variables.themePalette.colorWarning} />
        }
      >
        Заметки
      </UiCard.Header.Title>
    </UiCard.Header>
  );

  return (
    <UiCard size="default" title={Title} emptyPadding>
      <UiCard.Content
        basePadding={isNotesListEmpty}
        style={{ padding: isNotesListEmpty ? undefined : listPadding }}
      >
        {loading && (
          <UiList
            split={false}
            rowKey={(i) => i}
            dataSource={createArrayMock(NOTES_LIST_LENGTH, (_, i) => i)}
            renderItem={() => (
              <UiList.Item style={{ padding: notePadding }}>
                <UiSpace size={space2XS} direction="vertical">
                  <UiSkeleton loading height={18} width={250} />
                  <UiSkeleton loading height={12} width={100} />
                </UiSpace>
              </UiList.Item>
            )}
          />
        )}

        {isNotesListEmpty && emptyNotesList}
        {!loading && widgetDataNotes.length > 0 && (
          <UiList
            split={false}
            rowKey={({ id }) => id}
            dataSource={widgetDataNotes.slice(0, NOTES_LIST_LENGTH)}
            renderItem={(note) => (
              <UiList.Item style={{ padding: notePadding }} onClick={() => onOpenNoteEditor(note)}>
                <UiSpace size={space2XS} direction="vertical">
                  <UiTypography.Text strong>
                    <UiTruncateMarkup truncate>{note.title}</UiTruncateMarkup>
                  </UiTypography.Text>

                  <UiTypography.Footnote type="secondary">
                    <UiSpace>
                      {getFormattedDate(note.createdAt, isToday(new Date(note.createdAt)) ? 'time' : 'date')}
                      <UiTruncateMarkup truncate>
                        {getPlainTextFromHtml(getActualEditorFormat(note.body).data)}
                      </UiTruncateMarkup>
                    </UiSpace>
                  </UiTypography.Footnote>
                </UiSpace>
              </UiList.Item>
            )}
          />
        )}
      </UiCard.Content>

      {!loading && widgetDataNotes.length > NOTES_LIST_LENGTH && (
        <UiCard.Footer>
          <UiButton label="Все заметки" block size="large" type="tertiary" onClick={onToggleOpenNotesList} />
          <UiModal type={UiModalTypes.Medium} isOpen={isOpenNotesList} onClose={onToggleOpenNotesList}>
            <NotesWidgetListModal notesList={widgetDataNotes} onOpenNoteEditor={onOpenNoteEditor} />
          </UiModal>
        </UiCard.Footer>
      )}
    </UiCard>
  );
};
