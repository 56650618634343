import {
  UiButton,
  UiCard,
  UiModal,
  UiModalTypes,
  UiSkeleton,
  UiSpace,
  UiTooltip,
  UiTypography,
  useModalBase,
} from '@vkph/ui';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { getSkillsStorage } from '@vkph/common/store/profile/skills';
import { ProfileInfoModel, SkillId, SkillType, SkillsUsersSteps, UserSkill } from '@vkph/common/types/models';
import { authService, createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';

import { SkillsButton } from './button/SkillsButton';
import { skillsTextMap } from './constants';
import { SkillsEmpty } from './empty/SkillsEmpty';
import { SkillsAddModal } from './modals/skills-add/SkillsAddModal';
import { SkillsChoiceModal } from './modals/skills-choice/SkillsChoiceModal';

const SHOW_SKILLS_COUNT = 10;
const SKILLS_MOCKS_COUNT = 8;

const skillsMocks = createArrayMock(SKILLS_MOCKS_COUNT, (_, id) => id);

export type SingleSkill = {
  userId: string;
  skillId: SkillId;
};

export type SkillsProps = {
  skillBtnClassName?: string;
  moreBtnClassName?: string;
  skillType: SkillType;
  singleSkill?: SingleSkill;
};

export const Skills: FC<SkillsProps> = (props) => {
  const { skillBtnClassName, moreBtnClassName, skillType, singleSkill } = props;
  const { confirm } = useModalBase();
  const currentUserId = authService.getCurrentUserId();
  let { id: userId = '' } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isChoiceOpen, setChoiceOpen] = useState(false);
  const { spaceXL } = useSpace();

  const onOpenUsers = (userSkill: UserSkill, step: SkillsUsersSteps) => {
    openGlobalModal(GlobalModalNames.SkillsUsers, { userSkill, skillType, userId, step });
  };

  if (singleSkill) {
    userId = singleSkill.userId;
  }

  const skillStorage = useMemo(() => getSkillsStorage({ userId, skillType }), [userId, skillType]);
  const { data: skillsListFull, loading: skillsListLoading } = useAbstractStorage(skillStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
    autoFetchParams: { userId, skillType },
    resetStoreOnUnmount: true,
  });

  const { deleteSkillEffect } = skillStorage;

  const skillsList = useMemo(
    () =>
      singleSkill ? skillsListFull.filter((item) => item.skill.id === singleSkill.skillId) : skillsListFull,
    [singleSkill, skillsListFull],
  );

  const { data: profileFullInfoData, loading: profileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
  );

  const { main: userProfileData } = profileFullInfoData || ({} as ProfileInfoModel);

  useEffect(() => setShowMore(SHOW_SKILLS_COUNT >= skillsList.length), [skillsList]);

  const isOwner = useMemo(() => userId === currentUserId, [userId]);
  const isUserActive = Boolean(userProfileData?.isActive);

  const toggleSkillsAdd = () => setIsAddOpen(!isAddOpen);
  const toggleSkillsChoice = () => setChoiceOpen(!isChoiceOpen);

  const getSkillsToggle = {
    [SkillType.Skill]: toggleSkillsAdd,
    [SkillType.Competence]: toggleSkillsChoice,
  };

  const toggleSkills = getSkillsToggle[skillType];

  const onSkillDelete = useCallback(
    (skillId: SkillId) => {
      confirm({
        title: `Удалить ${skillsTextMap[skillType].OneDown}?`,
        okText: 'Удалить',
        content: (
          <UiTypography.Footnote>
            {`${skillsTextMap[skillType].OneUp} и все его подтверждения будут удалены без возможности восстановления`}
          </UiTypography.Footnote>
        ),
        cancelText: 'Отменить',
        onOk: () => deleteSkillEffect({ skillId, skillType }),
      });
    },
    [skillType, deleteSkillEffect],
  );

  const isSingleSkill = Boolean(singleSkill);

  const skills = useMemo(() => {
    return skillsList.map((userSkill: UserSkill) => (
      <SkillsButton
        key={userSkill.skill.id}
        isOwner={isOwner}
        userSkill={userSkill}
        skillStorage={skillStorage}
        currentUserId={currentUserId}
        onSkillDelete={onSkillDelete}
        buttonClassName={skillBtnClassName}
        skillType={skillType}
        onOpenUsers={(step) => onOpenUsers(userSkill, step)}
      />
    ));
  }, [skillsList]);

  const isOpen = isAddOpen || isChoiceOpen;

  return (
    <UiSpace full size={0} direction="vertical" style={{ padding: spaceXL }}>
      {isSingleSkill && skillsListLoading && (
        <UiSkeleton loading={skillsListLoading} height={38} width={250} />
      )}
      {isSingleSkill && !skillsListLoading && <>{skills}</>}
      {!isSingleSkill && (
        <>
          <UiCard.Header
            emptyPadding
            style={{ paddingBottom: spaceXL }}
            action={
              isUserActive && (
                <UiTooltip placement="left" title={`Добавить ${skillsTextMap[skillType].OneDown}`}>
                  <UiButton type="link-secondary" icon={<AddCircleSvg />} onClick={toggleSkills} />
                </UiTooltip>
              )
            }
          >
            <UiCard.Header.Title>{skillsTextMap[skillType].ManyUp}</UiCard.Header.Title>
          </UiCard.Header>

          <UiSpace size={8} wrap>
            {skillsListLoading &&
              skillsMocks.map((id) => (
                <UiSkeleton key={id} loading={skillsListLoading} height={38} width={80 + id * 10} />
              ))}
            {!skillsListLoading && (
              <>
                {userProfileData &&
                  userProfileData.isActive &&
                  skills.slice(0, showMore ? skills.length : SHOW_SKILLS_COUNT)}
                {!showMore && (
                  <UiButton
                    loading={skillsListLoading}
                    type="tertiary"
                    className={moreBtnClassName}
                    label={`Ещё ${skills.length - SHOW_SKILLS_COUNT}`}
                    onClick={() => setShowMore(true)}
                  />
                )}
                {userProfileData && (!userProfileData.isActive || !skills.length) && (
                  <SkillsEmpty
                    isOwner={isOwner}
                    addSkill={toggleSkills}
                    userProfile={userProfileData}
                    loading={profileFullInfoLoading}
                    skillType={skillType}
                  />
                )}
              </>
            )}
          </UiSpace>
        </>
      )}

      <UiModal type={UiModalTypes.Small} isOpen={isOpen} isMaskClosable={false} onClose={toggleSkills}>
        {isAddOpen && <SkillsAddModal skillStorage={skillStorage} closeModal={toggleSkills} />}
        {isChoiceOpen && (
          <SkillsChoiceModal
            closeModal={toggleSkills}
            type={skillType}
            skills={skillsList}
            storage={skillStorage}
          />
        )}
      </UiModal>
    </UiSpace>
  );
};
