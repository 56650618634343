import { UiButton, UiCard, UiIcon, UiModal, UiModalTypes, UiNavbar } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';

import { useCurrentProfile } from '@vkph/common/providers';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { useSpace } from '@vkph/ui/hooks';
import DraftSvg from '@vkph/ui/svg/draft.svg';

import { PostCompound } from '../../post/compound';
import { WritePostProps } from '../WritePost';
import { NAVBAR_ACTIONS_ID, WritePostMobileActions } from './actions/WritePostMobileActions';

export const WritePostMobile: FC<WritePostProps> = (props) => {
  const { blog, isMainTimeline, postType } = props;
  const currentProfile = useCurrentProfile();
  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { spaceM, spaceXS } = useSpace();

  const isCurrentUserProfile = currentProfile.id === profileFullInfoData?.main?.id;

  const toggleModalOpen = () => setIsModalOpen((value) => !value);

  return (
    <>
      <UiCard
        styles={{ body: { padding: spaceM } }}
        style={{ marginBottom: isMainTimeline ? spaceXS : undefined }}
        size="default"
      >
        <UiButton
          block
          size="small"
          label="Написать пост"
          type="secondary"
          icon={<UiIcon component={DraftSvg} width={20} height={20} />}
          onClick={toggleModalOpen}
        />
      </UiCard>

      <UiModal type={UiModalTypes.FullScreen} isOpen={isModalOpen} onClose={toggleModalOpen}>
        <UiNavbar onBack={toggleModalOpen} title="Создать пост" extra={<div id={NAVBAR_ACTIONS_ID} />} />
        <UiModal.Content>
          <PostCompound.Edit
            blog={blog}
            postType={postType}
            onCancel={toggleModalOpen}
            onSuccess={toggleModalOpen}
            isShowSettingsVisibility={isMainTimeline || isCurrentUserProfile}
            postSettingsWrapper={({ children }) => (
              <WritePostMobileActions>{children}</WritePostMobileActions>
            )}
          />
        </UiModal.Content>
      </UiModal>
    </>
  );
};
