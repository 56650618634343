import { Notification } from '@vkph/components';
import { UiAvatarProps, UiRow, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { LinkContentModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../utils';

interface Props {
  notification: NotificationModel<LinkContentModel>;
}

export const NotificationGamificationThanks: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    content: { title },
  } = notification;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiRow style={{ marginBottom: 12 }}>
          <UiTypography.Text strong>
            <UiTruncateMarkup truncate lines={2}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Text>
        </UiRow>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
