import { NotificationTypes } from '@vkph/common/types/models';

export const notificationSkills = [
  NotificationTypes.SkillApproved,
  NotificationTypes.CompetenceApproved,
  NotificationTypes.SkillAssignToUser,
  NotificationTypes.CompetenceAssignToUser,
];

export const notificationBlogPost = [
  NotificationTypes.BlogEntryApproved,
  NotificationTypes.BlogEntryDeclined,
  NotificationTypes.BlogEntryRequireModeration,
  NotificationTypes.BlogEntryCreated,
  NotificationTypes.BlogEntryUpdated,
  NotificationTypes.BlogEntryUserMentioned,
];

export const notificationBlogsInvite = [
  NotificationTypes.BlogUserInvite,
  NotificationTypes.BlogUserInviteExpert,
  NotificationTypes.BlogUserInviteModerator,
];

export const notificationBlogsComment = [
  NotificationTypes.BlogEntryCommentCreated,
  NotificationTypes.BlogEntryCommentReply,
  NotificationTypes.BlogEntryCommentUserMentioned,
  NotificationTypes.BlogEntryCommentReactionCreated,
];

export const notificationBlogs = [
  NotificationTypes.BlogUserInviteRequestClosedBlog,
  NotificationTypes.BlogGroupAdministratorIsBlocked,
  NotificationTypes.BlogEntryReactionCreated,
  ...notificationBlogsComment,
  ...notificationBlogsInvite,
  ...notificationBlogPost,
];

export const notificationFile = [
  NotificationTypes.BlogGroupFileCreated,
  NotificationTypes.FileVersionCommentCreated,
  NotificationTypes.FileVersionCommentReply,
  NotificationTypes.FileVersionCommentUserMentioned,
  NotificationTypes.FileVersionCommentReactionCreated,
];

export const notificationEvents = [NotificationTypes.EventsInvite, NotificationTypes.EventsDelete];

export const notificationGamification = [
  NotificationTypes.GamificationNewThanks,
  NotificationTypes.GamificationEventsManualGiveBadges,
  NotificationTypes.GamificationEventsAutomaticGiveBadges,
  NotificationTypes.UserBadgeCommentCreated,
  NotificationTypes.UserBadgeCommentUserMentioned,
  NotificationTypes.UserBadgeCommentReactionCreated,
  NotificationTypes.UserBadgeCommentReply,
  NotificationTypes.UserBadgeReactionCreated,
  NotificationTypes.ThanksCommentCreated,
  NotificationTypes.ThanksCommentUserMentioned,
  NotificationTypes.ThanksCommentReactionCreated,
  NotificationTypes.ThanksCommentReply,
  NotificationTypes.ThanksReactionCreated,
];

export const notificationRecord = [
  NotificationTypes.RecordCommentCreated,
  NotificationTypes.RecordCommentUserMentioned,
  NotificationTypes.RecordCommentReactionCreated,
  NotificationTypes.RecordCommentReply,
];

export const notificationSurvey = [NotificationTypes.SurveyPublished];

export const notificationCalendarGroupEvent = [
  NotificationTypes.CalendarGroupEventAttendeeApproveDecision,
  NotificationTypes.CalendarGroupEventAttendeeRejectDecision,
  NotificationTypes.CalendarGroupEventAttendeeMaybeDecision,
  NotificationTypes.CalendarGroupEventInvite,
  NotificationTypes.CalendarGroupEventUpdated,
  NotificationTypes.CalendarGroupEventDestroyed,
  NotificationTypes.CalendarGroupEventComingSoon,
  NotificationTypes.CalendarGroupEventDeleteAttendee,
];

export const notificationIdea = [
  NotificationTypes.IdeaApprove,
  NotificationTypes.IdeaDecline,
  NotificationTypes.IdeaRequiredReview,
  NotificationTypes.IdeaSetResponsible,
  NotificationTypes.IdeaCommentCreated,
  NotificationTypes.IdeaCommentReply,
  NotificationTypes.IdeaCommentReactionCreated,
];

export const notificationMicropost = [
  NotificationTypes.MicropostCommentCreated,
  NotificationTypes.MicropostCommentUserMentioned,
  NotificationTypes.MicropostCommentReply,
  NotificationTypes.MicropostCommentReactionCreated,
  NotificationTypes.MicropostReactionCreated,
  NotificationTypes.BlogMicropostUserMentioned,
  NotificationTypes.TimelinesSignerMicropostCreated,
];

export const notificationNews = [
  NotificationTypes.NewsCommentCreated,
  NotificationTypes.NewsCommentUserMentioned,
  NotificationTypes.NewsCommentReply,
  NotificationTypes.NewsCommentReactionCreated,
  NotificationTypes.NewsUserMentioned,
];

export const notificationAlbumImage = [
  NotificationTypes.AlbumImageCommentCreated,
  NotificationTypes.AlbumImageCommentUserMentioned,
  NotificationTypes.AlbumImageCommentReply,
  NotificationTypes.AlbumImageCommentReactionCreated,
];

export const notificationSitector = [
  NotificationTypes.SitectorCommentCreated,
  NotificationTypes.SitectorCommentReply,
  NotificationTypes.SitectorCommentReactionCreated,
  NotificationTypes.SitectorCommentUserMentioned,
  NotificationTypes.SitectorPageUserMentioned,
  NotificationTypes.SitectorPageReactionCreated,
];

export const notificationTasks = [
  NotificationTypes.TasksCompleted,
  NotificationTypes.TasksAssignee,
  NotificationTypes.TasksChangeAttributes,
  NotificationTypes.TaskCommentReactionCreated,
];

export const notificationList = [
  NotificationTypes.ListRowCreated,
  NotificationTypes.ListRowUpdated,
  NotificationTypes.ListRowDeleted,
];

export const notificationFileStorage = [NotificationTypes.FileStorageFileShared];

export const notificationProjects = [NotificationTypes.ProjectCommentReactionCreated];
