import { NotificationContent, NotificationModel } from '@vkph/common/types/models';

import { isNotificationOld } from '../../types/guards/notification-guards';
import { notificationDescriptionValues } from './notification/constants';

export const getNotificationDescription = (notification: NotificationModel<NotificationContent>) => {
  if (isNotificationOld(notification)) {
    return notification.content.description;
  }

  return notificationDescriptionValues?.[notification.notificationType] || 'Уведомление';
};
