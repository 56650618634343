import { CardLinks, CardLinksProps } from '@vkph/components';
import { UiList } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { WidgetModel } from '@vkph/common/types/models';
import { RouteNames, authService, getRoutePath } from '@vkph/common/utils';
import CaseSvg from '@vkph/ui/svg/case.svg';
import MyDepartmentSvg from '@vkph/ui/svg/my-department.svg';
import StudySvg from '@vkph/ui/svg/study.svg';
import TeamSvg from '@vkph/ui/svg/team.svg';

import { WidgetModule } from '../../types';

enum CustomLinkType {
  ToProfile = 'to-profile',
}

enum CardTypes {
  TeamWork = 'team_work',
  PersonnelService = 'personal_service',
  Structure = 'structure',
  TalentManagement = 'talent_management',
}

const cardIcons: Record<CardTypes, SvgrComponent> = {
  [CardTypes.TeamWork]: TeamSvg,
  [CardTypes.PersonnelService]: CaseSvg,
  [CardTypes.Structure]: MyDepartmentSvg,
  [CardTypes.TalentManagement]: StudySvg,
};

type WidgetCardLink = {
  title: string;
} & ({ customLinkType: CustomLinkType } | { link: string });

interface WidgetData extends Omit<CardLinksProps, 'links'> {
  links: WidgetCardLink[];
  type: CardTypes;
}

interface WidgetProps extends Omit<WidgetModel, 'data' | 'defaultData'> {
  data: WidgetData[];
}

interface Props extends WidgetModule {
  widget: WidgetProps;
}

export const NavigationWidget: FC<Props> = (props) => {
  const {
    widget: { data: widgetData },
  } = props;
  const keycloakId = authService.getCurrentUserId();

  const widgetDataWithModifiedLinks: CardLinksProps[] = useMemo(() => {
    return widgetData.map((widgetInfo) => ({
      ...widgetInfo,
      icon: cardIcons[widgetInfo.type],
      links: widgetInfo.links.map((widgetCardLink) => {
        if (
          keycloakId &&
          'customLinkType' in widgetCardLink &&
          widgetCardLink.customLinkType === CustomLinkType.ToProfile
        ) {
          return {
            link: getRoutePath(RouteNames.Profile, { id: keycloakId }),
            title: widgetCardLink.title,
          };
        }

        return {
          link: 'link' in widgetCardLink ? widgetCardLink.link : '',
          title: widgetCardLink.title,
        };
      }),
    }));
  }, [widgetData, keycloakId]);

  return (
    <UiList
      grid={{ gutter: 20, column: 2 }}
      dataSource={widgetDataWithModifiedLinks}
      renderItem={(widgetInfo) => (
        <UiList.Item>
          <CardLinks header={widgetInfo.header} icon={widgetInfo.icon} links={widgetInfo.links} />
        </UiList.Item>
      )}
    />
  );
};
