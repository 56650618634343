import {
  UiButton,
  UiCheckbox,
  UiCheckboxChangeEvent,
  UiDivider,
  UiFlex,
  UiForm,
  UiIcon,
  UiMenu,
  UiModal,
  UiModalTypes,
  UiSpace,
  UiTruncateMarkup,
  message,
  useBreakpoint,
  usePaddingStyle,
  useSpace,
} from '@vkph/ui';
import classNames from 'classnames';
import { useStore, useStoreMap } from 'effector-react';
import flatMap from 'lodash/flatMap';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useAbstractStorage, useLocalStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers';
import {
  getNavigationListStorage,
  getFavoriteNavigationStorage,
  getNavigationMenuSettingsStorage,
} from '@vkph/common/store/navigation';
import { getPathnameWithoutParams, routes } from '@vkph/common/utils';
import { useOnClickOutside, useToggle } from '@vkph/ui/hooks';
import ArrowBackSvg from '@vkph/ui/svg/arrow-back.svg';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';
import GearSvg from '@vkph/ui/svg/gear.svg';

import { NavigationItem, NavigationMenuItem } from '../../../../types';
import { NavigationEdit } from '../edit/NavigationEdit';
import { NavigationFavorites } from '../favorites/NavigationFavorites';
import styles from './NavigationMenu.scss';
import { MenuLinkTruncated } from './link-truncated/MenuLinkTruncated';
import { getCheckedItems } from './utils';

type NavigationBookmarkItemId = string;

interface FormCheckedField extends NavigationItem {
  checked: boolean;
}

export interface FavoriteItem extends Pick<FormCheckedField, 'checked'> {
  id: NavigationBookmarkItemId;
}

type FormCheckedValues = {
  menu: Record<string, FormCheckedField>;
  favorites: FormCheckedField[];
};

type Props = {
  className?: string;
};

export const NavigationMenu: FC<Props> = (props) => {
  const { className } = props;
  const location = useLocation();
  const navigationRef = useRef<HTMLDivElement>(null);
  const [form] = UiForm.useForm<FormCheckedValues>();
  const [openKeys, setOpenKeys] = useLocalStorage<string[]>('openedNavigationKeys', []);
  const [isNavbarHidden, toggleNavbar] = useLocalStorage('isNavbarHidden', false);
  const { xl: isLayoutExtraLarge } = useBreakpoint();
  const { spaceM, spaceL } = useSpace();
  const wrapperPadding = usePaddingStyle([0, spaceL, spaceM]);
  const navigationListStorage = useMemo(getNavigationListStorage, []);
  const favoriteNavigationStorage = useMemo(getFavoriteNavigationStorage, []);
  const navigationMenuSettingsStorage = useMemo(getNavigationMenuSettingsStorage, []);
  const { paramsStore } = favoriteNavigationStorage;

  const profile = useCurrentProfile();

  const { data: navigationMenu } = useAbstractStorage(navigationListStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: favoritesMenu } = useAbstractStorage(favoriteNavigationStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: navigationMenuSettings } = useAbstractStorage(navigationMenuSettingsStorage.storage, {
    autoFetchAndRefetch: profile.superAdmin,
  });

  const [isEditMenuOpen, toggleIsEditMenuOpen] = useToggle([false, true]);
  const { isFavoritesEdit = false, isFieldsChanged } = useStore(paramsStore.store);
  const { updateStoreEvent: updateNavigationMenuStore } = paramsStore;
  const { updateFavoriteNavigationEffect } = favoriteNavigationStorage;

  const isUpdateUserWidgetDataPending = useStore(updateFavoriteNavigationEffect.pending);

  const navigationMenuItems = useStoreMap(navigationListStorage.storage.store, (store) =>
    store.data.map(({ items }) => items)?.flat(),
  );

  const initialValues = useMemo(() => {
    return {
      favorites: favoritesMenu,
      menu: navigationMenu.length ? navigationMenu : null,
    };
  }, [favoritesMenu, navigationMenu]);

  useEffect(() => {
    form.setFieldsValue({
      favorites: favoritesMenu.map((fav) => ({ ...fav, checked: true })),
    });
  }, [favoritesMenu]);

  const navigationSectionsIdsSet = useMemo(
    () => new Set(flatMap(navigationMenu, 'id').map((id) => id)),
    [navigationMenu],
  );

  const isValidOpenKey = useCallback(
    (key: unknown) => typeof key === 'string' && navigationSectionsIdsSet.has(key),
    [navigationSectionsIdsSet],
  );

  const validatedOpenKeys = useMemo(
    () => openKeys.filter(isValidOpenKey),
    [openKeys, navigationSectionsIdsSet],
  );

  const onClickEditHandler: React.MouseEventHandler<HTMLElement> = () => {
    updateNavigationMenuStore({ isFavoritesEdit: !isFavoritesEdit, isFieldsChanged });
  };

  const onCancelEditHandler = () => {
    const favoritesForm = favoritesMenu.map((el) => ({ ...el, checked: true }));
    const { menu: newMenu } = getCheckedItems(favoritesForm, navigationMenuItems);

    form.setFieldsValue({
      favorites: favoritesForm,
      menu: newMenu,
    });

    updateNavigationMenuStore({ isFavoritesEdit: !isFavoritesEdit, isFieldsChanged: false });
  };

  const hideFavoritesEdit = useCallback(() => {
    if (isFavoritesEdit) {
      onCancelEditHandler();
    }
  }, [isFavoritesEdit]);

  useOnClickOutside(navigationRef, hideFavoritesEdit);

  const onSuccessEditHandler: React.MouseEventHandler<HTMLElement> = () => {
    const favorites: FavoriteItem[] = form.getFieldValue('favorites');

    updateFavoriteNavigationEffect({
      itemsIds: favorites.filter((filter) => filter.checked).map(({ id }) => id),
    }).then(() => {
      message.success('Сохранено');
      updateNavigationMenuStore({ isFavoritesEdit: !isFavoritesEdit, isFieldsChanged: false });
    });
  };

  const onFieldsChange = useCallback(() => {
    updateNavigationMenuStore({ isFieldsChanged: true, isFavoritesEdit });
  }, [isFieldsChanged, isFavoritesEdit]);

  const MenuTitle = ({ title }: { title: string }) => (
    <UiTruncateMarkup truncate tooltipProps={{ title, placement: 'right' }}>
      {title}
    </UiTruncateMarkup>
  );

  const checkboxStyles = useMemo(
    () =>
      classNames({
        [styles.navigationMenu__checkbox]: isFavoritesEdit,
        [styles.navigationMenu__checkbox_hide]: !isFavoritesEdit,
      }),
    [isFavoritesEdit],
  );

  const onCheckboxFavoritesChange = ({ target }: UiCheckboxChangeEvent, name: string | undefined) => {
    const { checked } = target;
    const itemId = navigationMenuItems.find(({ url }) => url === name)?.id;

    if (!itemId) return;

    if (name) {
      const namePath = ['menu', itemId];
      const value = { ...form.getFieldValue(namePath), checked };

      form.setFields([{ name: namePath, value }]);
    }
  };

  const onCheckboxMenuChange = ({ target }: UiCheckboxChangeEvent) => {
    const { name, checked } = target;
    const navigationMenuItem = navigationMenuItems.find(({ id }) => id === name);

    if (!navigationMenuItem) {
      return;
    }

    const favorites = form.getFieldValue('favorites');
    const isFavoritesContainsItem = favorites.find((fav: FavoriteItem) => fav.id === navigationMenuItem.id);

    if (!isFavoritesContainsItem) {
      form.setFieldsValue({
        favorites: [...favorites, { ...navigationMenuItem, checked }],
      });
      return;
    }

    const newFavoritesState = favorites.map((fav: FavoriteItem) =>
      fav.id === navigationMenuItem.id ? { ...fav, checked } : fav,
    );

    form.setFieldsValue({
      favorites: newFavoritesState,
    });
  };

  const navigationMenuClassName = useMemo(
    () =>
      classNames(
        styles.navigationMenu,
        { [styles.navigationMenu__scrollbar_hide]: isFavoritesEdit },
        className,
      ),
    [className, isFavoritesEdit],
  );

  const selectedKeys = useMemo(() => {
    const keys: string[] = [];

    navigationMenuItems.forEach((item) => {
      if (location.pathname === getPathnameWithoutParams(item.url)) {
        keys.push(item.id);
      }
    });

    if (!keys.length) {
      const routesMap = Object.values(routes).map((route) => ({ path: route }));
      const matches = matchRoutes(routesMap, location.pathname) || [];
      const [path = ''] = matches.map(({ route }) => route.path);

      navigationMenuItems.forEach((item) => {
        if (path.includes(item.url)) {
          keys.push(item.id);
        }
      });
    }

    return keys;
  }, [location.pathname, navigationMenuItems]);

  const renderNavigationMenuItem = ({ label, id, blank, url }: NavigationMenuItem) => {
    return {
      label: (
        <UiSpace>
          <UiForm.Item noStyle name={['menu', id, 'checked']} valuePropName="checked" shouldUpdate>
            <UiCheckbox
              name={id}
              disabled={isUpdateUserWidgetDataPending}
              className={checkboxStyles}
              onChange={onCheckboxMenuChange}
            />
          </UiForm.Item>
          {isFavoritesEdit && <UiTruncateMarkup truncate>{label}</UiTruncateMarkup>}
          {!isFavoritesEdit && <MenuLinkTruncated url={url} label={label} blank={blank} id={id} />}
        </UiSpace>
      ),
      key: id,
    };
  };

  const menuItems = useMemo(() => {
    return navigationMenu.map((navigationGroup) => {
      const { items, name, id } = navigationGroup;

      return {
        label: <MenuTitle title={name} />,
        key: id,
        icon: <UiIcon component={ArrowExpandSvg} width={20} height={20} />,
        children: items.map(renderNavigationMenuItem),
      };
    });
  }, [navigationMenu, isFavoritesEdit]);

  const buttonText = isNavbarHidden ? 'Закрепить меню' : 'Скрыть меню';

  const onClick = () => {
    toggleNavbar(!isNavbarHidden);
  };

  return (
    <div ref={navigationRef} className={navigationMenuClassName}>
      {isLayoutExtraLarge && (
        <UiFlex style={wrapperPadding}>
          <UiButton
            type="link"
            icon={<UiIcon component={ArrowBackSvg} width={20} height={20} />}
            onClick={onClick}
            label={buttonText}
          />
        </UiFlex>
      )}
      <UiForm form={form} name="favorites-form" initialValues={initialValues} onFieldsChange={onFieldsChange}>
        <NavigationFavorites
          selectedKeys={selectedKeys}
          isFavoritesEdit={isFavoritesEdit}
          isFavoritesEmpty={!favoritesMenu.length}
          isSuccessEditDisabled={!isFieldsChanged}
          isSuccessEditLoading={isUpdateUserWidgetDataPending}
          onClickEditHandler={onClickEditHandler}
          onCancelEditHandler={onCancelEditHandler}
          onSuccessEditHandler={onSuccessEditHandler}
          onCheckboxFavoritesChange={onCheckboxFavoritesChange}
        />
        <UiDivider />
        <UiMenu
          mode="inline"
          inlineIndent={20}
          openKeys={validatedOpenKeys}
          selectedKeys={selectedKeys}
          triggerSubMenuAction="click"
          expandIcon={() => null}
          onOpenChange={setOpenKeys}
          items={menuItems}
        />
        {profile?.superAdmin && (
          <div className={styles.navigationMenu__editMenu}>
            <UiButton
              disabledFocus
              icon={<UiIcon component={GearSvg} width={20} height={20} />}
              className={styles.navigationMenu__editMenuBtn}
              onClick={() => toggleIsEditMenuOpen()}
            >
              Настроить меню
            </UiButton>
            <UiModal type={UiModalTypes.Medium} isOpen={isEditMenuOpen} onClose={toggleIsEditMenuOpen}>
              <NavigationEdit
                navigation={navigationMenuSettings}
                onClose={toggleIsEditMenuOpen}
                navigationSettingsStorage={navigationMenuSettingsStorage}
              />
            </UiModal>
          </div>
        )}
      </UiForm>
    </div>
  );
};
