import {
  useBreakpoint,
  UiTypography,
  UiTruncateMarkup,
  UiIcon,
  UiTypographySymbol,
  UiTypographySymbolName,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { EventModel } from '@vkph/common/types/models';
import { declension, memberDeclension, getFormattedDateRange } from '@vkph/common/utils';
import CalendarSVG from '@vkph/ui/svg/calendar.svg';
import CommunitySVG from '@vkph/ui/svg/community.svg';
import LocationSVG from '@vkph/ui/svg/location.svg';

import styles from './EventListItem.scss';

type Props = {
  event: EventModel;
  onSelect: (event: EventModel) => void;
};

export const EventListItem: FC<Props> = (props) => {
  const { event, onSelect } = props;
  const { fileStorageImageUrl } = event;
  const backgroundImageUrl = fileStorageImageUrl ? `url("${fileStorageImageUrl}")` : undefined;
  const placeText = event.place === 'online' ? 'Онлайн' : event.place;

  const { since, till } = event;
  const dateText = getFormattedDateRange(since, till, 'date');
  const timeText = getFormattedDateRange(since, till, 'time');

  const participantsText = `${event.memberTotalUids.length} ${declension(
    event.memberTotalUids.length,
    memberDeclension,
  )}`;
  const { sm: isLayoutSmall } = useBreakpoint();

  return (
    <div
      role="link"
      tabIndex={-1}
      onClick={() => onSelect(event)}
      onKeyPress={() => onSelect(event)}
      className={classNames(styles.eventListItem, {
        [styles.eventListItem_small]: !isLayoutSmall,
      })}
    >
      <div
        className={styles.eventListItem__image}
        style={{
          backgroundImage: backgroundImageUrl,
        }}
      />
      <div className={styles.eventListItem__info}>
        <UiTruncateMarkup
          className={styles.eventListItem__infoTitle}
          truncate
          tooltipProps={{ title: event.name }}
        >
          {event.name}
        </UiTruncateMarkup>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={LocationSVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTruncateMarkup
            truncate
            tooltipProps={{ title: placeText }}
            className={styles.eventListItem__infoItemPlace}
          >
            {placeText}
          </UiTruncateMarkup>
        </div>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={CalendarSVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTypography.Text type="secondary" strong>
            {dateText} <UiTypographySymbol name={UiTypographySymbolName.Bullet} /> {timeText}
          </UiTypography.Text>
        </div>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={CommunitySVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTypography.Text type="secondary" strong>
            {participantsText}
          </UiTypography.Text>
        </div>
      </div>
    </div>
  );
};
