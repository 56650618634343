import { UiCard, UiEmpty } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { WidgetModel } from '@vkph/common/types/models';

import { getWeatherStorage } from '../../store/weather';
import { WidgetModule, Weather as WeatherNamespace } from '../../types';
import styles from './Weather.scss';
import { WeatherCurrent } from './current/WeatherCurrent';
import { WeatherCurrentLoader } from './current/loading/WeatherCurrentLoading';
import { WeatherDay } from './day/WeatherDay';
import { WeatherHeader } from './header/WeatherHeader';
import { WeatherLoading } from './loading/WeatherLoading';

export interface WeatherProps extends WidgetModule {
  widget: WidgetModel;
}

const MAX_DAYS_COUNT = 4;

export const Weather: FC<WeatherProps> = (props) => {
  const { widget } = props;
  const { cityId, cityName } = widget.attributes?.data || {};

  const weatherStorage = useMemo(() => getWeatherStorage({ cityId }), [cityId]);
  const {
    data: weather,
    loading: weatherLoading,
    error: weatherError,
  } = useAbstractStorage(weatherStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const dailyWeather = weather?.daily?.slice(1, MAX_DAYS_COUNT + 1);
  const currentWeather = useMemo(
    () => (!weather?.hourly?.length ? weather?.current : weather?.hourly?.[0]),
    [weather],
  );
  const isWeatherInfoEmpty = currentWeather === undefined || weatherLoading;

  return (
    <UiCard title={<WeatherHeader locationName={!isWeatherInfoEmpty && cityName} />}>
      {weatherError && <UiEmpty.Feed emptyMessage={{ header: 'Для вашего города нет данных' }} />}
      {!weatherError && weatherLoading && (
        <>
          <WeatherCurrentLoader />
          <div className={styles.weather__daily}>
            <WeatherLoading maxCount={MAX_DAYS_COUNT} />
          </div>
        </>
      )}
      {!weatherError && !weatherLoading && (
        <>
          <WeatherCurrent currentWeather={currentWeather} />
          <div className={styles.weather__daily}>
            {dailyWeather?.map((day: WeatherNamespace.Day) => day && <WeatherDay key={day.date} day={day} />)}
          </div>
        </>
      )}
    </UiCard>
  );
};
