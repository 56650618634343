import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiButton, UiSpace } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { fileStorageContextRouteMap, getFileStorageRouteParams } from '@vkph/common/store/filestorage';
import { FileStorageListEntryModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../utils';

interface Props {
  notification: NotificationModel<FileStorageListEntryModel>;
}

export const NotificationFileStorage: FC<Props> = (props) => {
  const { notification } = props;
  const { createdAt, content, image, initiator } = notification;
  const { context, id } = content;
  const navigate = useNavigate();

  const notificationTitle = getFullNameWithoutPatronymic(initiator.fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const notificationDescription = getNotificationDescription(notification);

  const routeParams = getFileStorageRouteParams(content);

  const routPathToFile = useMemo(() => {
    if (id && context) {
      return getRoutePath(fileStorageContextRouteMap[context.service], { ...routeParams, fileId: id });
    }

    return null;
  }, [id, context, routeParams]);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: initiator.keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={initiator.isActive}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          {routPathToFile && (
            <>
              <RouterLink to={routPathToFile}>{content.name}</RouterLink>
              <UiButton label="Посмотреть" type="primary" onClick={() => navigate(routPathToFile)} />
            </>
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
