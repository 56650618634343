import { UploadDraggerArea } from '@vkph/components';
import { UiButton, UiCard, UiCol, UiFile, UiRow, UiUploadRequestOption, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { MouseEvent, TouchEvent, useEffect, useMemo, useState } from 'react';

import {
  getFileFromStorageV2,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import { FileId, FileModel, PollQuestionModel, VoteModel } from '@vkph/common/types/models';
import { FileUploadAccepts, checkFileSizeUploadEffect, getFormattedDate } from '@vkph/common/utils';
import ClearSVG from '@vkph/ui/svg/clear.svg';
import { getFormattedFileSize } from '@vkph/ui/utils';

type FileInputProps = {
  question: PollQuestionModel;
  onChange?: (vote: VoteModel[] | null) => void;
  disabled?: boolean;
  value?: FileId;
};

export const FileInput: React.FC<FileInputProps> = (props) => {
  const { onChange, disabled, question, value } = props;

  const uploadAttachmentEffect = useMemo(() => uploadFileStorageAttachmentEffectFactory(), []);
  const isLoading = useStore(uploadAttachmentEffect.pending);
  const [uploadedFile, setUploadedFile] = useState<FileModel | null>(null);

  useEffect(() => {
    if (value) {
      getFileFromStorageV2({
        id: value,
      }).then(({ data }) => setUploadedFile(data));
    } else {
      setUploadedFile(null);
    }
  }, [value]);

  const onRemoveFile = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    setUploadedFile(null);
    onChange?.(null);
  };

  const onUploadAttachmentEffect = async ({ file }: UiUploadRequestOption) => {
    try {
      const fileUpload = await checkFileSizeUploadEffect({ file: file as File, maxSize: 10 });
      const fileModel = await uploadAttachmentEffect(fileUpload);
      const voteModel = [{ value: String(fileModel.id), question: question.id }];

      setUploadedFile(fileModel);
      onChange?.(voteModel);

      return fileUpload;
    } catch (e) {
      return message.error(String(e));
    }
  };

  if (uploadedFile) {
    const fileUpdateDate = uploadedFile.updated ? `• ${getFormattedDate(uploadedFile.updated, 'date')}` : '';

    return (
      <UiCard size="default">
        <UiRow align="middle">
          <UiCol span={23}>
            <UiFile
              title={uploadedFile.name}
              subtitle={`${getFormattedFileSize(uploadedFile.fileSize)} ${fileUpdateDate}`}
              fileName={uploadedFile.name}
            />
          </UiCol>

          <UiCol span={1}>
            <UiButton
              type="link-secondary"
              onClick={onRemoveFile}
              icon={<ClearSVG />}
              disabled={disabled}
              size="middle"
            />
          </UiCol>
        </UiRow>
      </UiCard>
    );
  }

  return (
    <UploadDraggerArea
      disabled={disabled}
      onUploadAttachment={onUploadAttachmentEffect}
      loading={isLoading}
      multiple={false}
      accept={FileUploadAccepts.All}
    />
  );
};
