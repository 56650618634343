import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationModel, NotificationUserBadgeReactionModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../utils';

interface Props {
  notification: NotificationModel<NotificationUserBadgeReactionModel>;
}

export const NotificationGamificationRewardReaction: FC<Props> = (props) => {
  const { notification } = props;
  const {
    status,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
  } = notification;

  const description = getNotificationDescription(notification);
  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  const profileRewardLocation = getRoutePath(RouteNames.ProfileRewardsProgress, {
    id: notification.content.userBadge.user.id,
    rewardId: notification.content.userBadge.id,
  });

  return (
    <Notification status={status}>
      <Notification.Header
        title={title}
        subtitle={description}
        avatarProps={avatar}
        to={userLink}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <RouterLink to={profileRewardLocation}>
            {notification.content.userBadge.badge.name || 'Перейти к награде'}
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
