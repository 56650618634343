import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationModel, NotificationSitectorReactionModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../utils';

type Props = {
  notification: NotificationModel<NotificationSitectorReactionModel>;
};

export const NotificationSitectorReaction: FC<Props> = (props) => {
  const { notification } = props;
  const {
    status,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
  } = notification;

  const description = getNotificationDescription(notification);
  const title = getFullNameWithoutPatronymic(fullName);

  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  const { sitector } = notification.content;

  return (
    <Notification status={status}>
      <Notification.Header
        title={title}
        subtitle={description}
        avatarProps={avatar}
        to={userLink}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace direction="vertical" full>
          <RouterLink to={getRoutePath(RouteNames.Site, { slug: sitector.site?.slug })}>
            <UiTruncateMarkup lines={2} truncate>
              {sitector.site?.name}
            </UiTruncateMarkup>
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
